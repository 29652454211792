import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './assets/scss/app.scss';
import { LoginModal } from './components/Modals/LoginModal';
import { Header } from './components/Header/Header';
import { Footer } from "./components/Footer/Footer";
import { Home } from './pages/Home/Home';
import { Shop } from './pages/Shop/Shop';
import { RegisterModal } from './components/Modals/RegisterModal';
import { Profile } from './pages/Profile/Profile';
import { Blog } from './pages/Blog/Blog';
import { AccountDetails } from './pages/Profile/AccountDetails/AccountDetails';
import OrderHistory from './pages/Profile/OrderHistory/OrderHistory';
import { AccountDisable } from './pages/Profile/AccountDisable/AccountDisable';
import { BlogDetail } from './pages/Blog/BlogDetail';
import { BlogList } from './pages/Blog/BlogList';
import axios from 'axios';
import { ProductDetail } from './pages/Shop/ProductDetail';
import { Products } from './pages/Shop/Products';
import Cart from './components/Cart/Cart';
import { useStateContext } from './context/StateContext';
import About from './pages/About/About';
import Checkout from './pages/Checkout/Checkout';
import CheckoutForm from './pages/Checkout/CheckoutForm';
import CheckoutSuccess from "./pages/Checkout/CheckoutSuccess";
import ForgotPasswordModal from "./components/Modals/ForgotPasswordModal";
import ReturnsPolicy from "./pages/Paperwork/ReturnsPolicy";
import TermsAndConditions from "./pages/Paperwork/TermsAndConditions";
import Gdpr from "./pages/Paperwork/Gdpr";
import PaymentDeliveryConditions
  from "./pages/Paperwork/PaymentDeliveryCondition";


// axios.defaults.baseURL = 'https://api.viabotanica.hu/api/';
axios.defaults.baseURL = 'https://api.viabotanica.eu/api/';
// axios.defaults.baseURL = 'http://localhost:1337/api/'
axios.defaults.withCredentials = true;

export const App = () => {
  const {
    setShowCart,
    loginModal,
    setLoginModal,
    forgotPasswordModal,
    registerModal,
    consentModal,
    menuVisibility,
    setMenuVisibility,
    setLocale,
  } = useStateContext();
  const location = useLocation();
  const [loginMessage, setLoginMessage] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    setMenuVisibility(false);
    setShowCart(false);
    window.scrollTo(0, 0);
    const header = document.getElementsByClassName('header').item(0);
    header?.classList.remove('--grey');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (searchParams.has('confirmation')) {
      setLoginModal(true);
      setLoginMessage('Email bol úspešne overený');
    } else {
      setLoginMessage(null);
    }

    const searchLocale = searchParams.get('locale');
    if (searchLocale !== null) {
      setLocale(searchLocale);
    }
  })



  return (
    <div className={"App" + (menuVisibility ? " mobile-menu-expanded" : "")}
      id='app'>
      {loginModal && <LoginModal message={loginMessage} />}
      {registerModal && <RegisterModal />}
      {forgotPasswordModal && <ForgotPasswordModal />}
      <Header />
      {/*{consentModal && <ConsentModal/>}*/}
      <Cart />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/o-nas' element={<About />} />
        <Route path='/produkty' element={<Products />}>
          <Route path=":productId" element={<ProductDetail />} />
          <Route index element={<Shop />} />
        </Route>
        <Route path='/blogs' element={<Blog />}>
          <Route path=":blogId" element={<BlogDetail />} />
          <Route index element={<BlogList />} />
        </Route>
        <Route path='/kosik' element={<Cart />} />
        <Route path='/kontrola-objednavky' element={<Checkout />}>
          <Route index element={<CheckoutForm />} />
        </Route>
        <Route path="/kontrola-objednavky/uspech" element={<CheckoutSuccess />} />
        <Route path="/moj-ucet" element={<Profile />}>
          <Route path="historia-objednavok" element={<OrderHistory />} />
          <Route path="zmena-hesla" element={<AccountDisable />} />
          <Route index element={<AccountDetails />} />
        </Route>
        <Route path='/reklamacne-podmienky' element={<ReturnsPolicy />} />
        <Route path='/obchodne-podmienky' element={<TermsAndConditions />} />
        <Route path='/gdpr' element={<Gdpr />} />
        <Route path='/platba-dorucenie-podmienky'
          element={<PaymentDeliveryConditions />} />
      </Routes>
      <Footer />
    </div>
  );
}

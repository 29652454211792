import axios from "axios";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR, { Fetcher } from "swr";
import { Blog } from "../../modals/modals";
import ReactMarkdown from "react-markdown";
import './BlogDetail.scss';
import { transformUrl as apiUrl } from "../../utils/fetch";
import { useStateContext } from "../../context/StateContext";
import { localizedUrl } from "../../utils/locale";

export const BlogDetail = () => {
  const { host } = window.location;
  const { locale } = useStateContext();
  const navigate = useNavigate();

  let { blogId } = useParams();
  const fetcher: Fetcher<Blog> = (url: string) => axios.get(url).then(res => ({
    ...res.data.data.attributes,
    id: res.data.data.id
  }));
  const { data, error }: { data?: Blog, error?: any } = useSWR<Blog>(
    localizedUrl(`blog-posts/${blogId}`, {
      'populate[rows][populate][image][fields]': 'url',
      'populate': 'localizations',
    }, locale),
    fetcher
  );

  useEffect(() => {
    if (locale && data?.locale && locale !== data.locale) {
      const locId = data.localizations.data.find(loc => loc.id)?.id;
      navigate(locId ? `../${locId}` : '..');
    }
  }, [locale, data]);

  return (
    <div className="BlogDetail">
      <div className="row">
        <div className="container-fluid">
          <h3 className="mb-5 text-center text-md-left">{data?.title}</h3>
          {/* <div dangerouslySetInnerHTML={{ __html: data ? data.body : '' }} /> */}
          {data?.rows.map((row, index) => {
            const { id, title, image, text } = row;
            const textClass = 'd-flex flex-column align-items-center order-1 px-5'
              + (image ? ' col-12 col-md-6' : ' col-12')
              + (index % 2 === 0 ? ' order-md-1 text-md-start ps-md-5 pe-md-0' : ' order-md-0 text-md-end pe-md-5 ps-md-0');
            const imageClass = 'order-0 mb-3 mb-md-0 d-flex align-items-center'
              + (image ? ' col-10 col-md-6' : ' col-12')
              + (index % 2 === 0 ? ' order-md-0' : ' order-md-1');

            const imageCol = (
              <div className={imageClass}>
                {image
                  ? <img src={apiUrl(image.data.attributes.url, host)} className="img-fluid d-block" />
                  : null}
              </div>
            )
            const textCol = (
              <div className={textClass}>
                <h4
                  className={"mb-2 mb-md-3 text-center " + (index % 2 === 0 ? "text-md-start" : "text-md-end")}
                >
                  {title}
                </h4>
                <div
                  className="fs-2"
                  dangerouslySetInnerHTML={{ __html: data ? text : '' }}
                />
              </div>
            )
            return (
              <div key={id} className="row d-flex align-items-center mb-5 justify-content-center">
                {index % 2 === 0 ? imageCol : textCol}
                {index % 2 === 0 ? textCol : imageCol}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Blog } from "../../modals/modals";
import './BlogItem.scss'
import { transformUrl as apiUrl } from "../../utils/fetch";

export const BlogItem = ({ id, title, body, image, productCategory, rows, index }: (Blog & { index: number })) => {
  const { host } = window.location;
  // return (
  //     <Link to={`${id}`} className="col blog-preview">
  //         <div className="img-holder" style={ image.data? .attributes.url }>
  //             <span className="blog-category">{ productCategory ?? '' }</span>
  //         </div>
  //         <div>
  //             <div className="blog-preview__text">
  //                 <h3>{ title }</h3>
  //             </div>
  //         </div>
  //     </Link>
  // );
  const textClass = 'my-auto order-1 px-5'
    + (image ? ' col-12 col-md-6' : ' col-12')
    + (index % 2 === 0 ? ' order-md-1 text-md-start ps-md-5 pe-md-0' : ' order-md-0 text-md-end pe-md-5 ps-md-0');
  const imageClass = 'my-auto order-0 mb-3 mb-md-0'
    + (image ? ' col-10 col-md-6' : ' col-12')
    + (index % 2 === 0 ? ' order-md-0' : ' order-md-1');

  const imageCol = (
    <div className={imageClass}>
      {image.data
        ? <img src={apiUrl(image.data.attributes.url, host)} className="img-fluid" />
        : null}
    </div>
  )
  const textCol = (
    <div className={textClass}>
      <h4
        className={"mb-2 mb-md-3 text-center " + (index % 2 === 0 ? "text-md-start" : "text-md-end")}
      >
        {title}
      </h4>
      <div
        className="fs-2"
        dangerouslySetInnerHTML={{ __html: rows[0] ? rows[0].text : '' }}
      />
    </div>
  )

  return (
    // <div className="BlogItem">
    // <div className="row">
    // <div className="container-fluid">
    // {/* <h3 className="mb-5 text-center text-md-left">{data?.title}</h3> */}
    // {/* <div dangerouslySetInnerHTML={{ __html: data ? data.body : '' }} /> */}
    <Link to={`${id}`} className="row mb-5 justify-content-center blog-preview">
      {index % 2 === 0 ? imageCol : textCol}
      {index % 2 === 0 ? textCol : imageCol}
    </Link>
    // </div>
    // </div>
    // </div>
  );
}


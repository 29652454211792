import React, { useState, useEffect } from 'react';
import packetaLogo from '../../assets/images/packeta.svg';
import * as yup from "yup";
import './Checkout.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useStateContext } from '../../context/StateContext';
import _ from 'lodash';
import axios from "axios";
import useSWR, { Fetcher } from "swr";
import {
  useCurrentUser,
  useDispatchCurrentUser
} from "../../context/UserContext";
import SelectBox from "../../components/SelectBox/SelectBox";
import toast from "react-hot-toast";
import {
  countries,
  Country,
  DEFAULT_LOCALE,
  DeliveryMethod,
  DeliveryMethodKey,
  deliveryMethodKeys,
  DeliveryMethods,
  DeliveryOption,
  PaymentMethod,
  PaymentMethodKey,
  paymentMethodKeys,
  PaymentMethods
} from "../../assets/constants";
import Spinner from "../../components/Spinner/Spinner";
import { IDeliveryAdress, User, CashbackLevel, CheckoutPage } from '../../modals/modals';
import { registerUser } from "../../utils/user";
import { UserAction } from '../../enums/enums';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Modal from '../../components/Modals/Modal';
import Loader from '../../components/Loader/Loader';
import { Currency, getCurrencyFromLocale, getDefaultLocaleFromCountry, localizedUrl } from '../../utils/locale';

declare global {
  interface Window {
    Packeta: any;
  }
}

const firstOrDefault = (array: IDeliveryAdress[] | undefined | null, key: string, defaultValue?: string): string => {
  if (!array) {
    return defaultValue ?? '';
  }
  const first = array[0];
  if (!first) {
    return defaultValue ?? '';
  }
  return first[key] ?? defaultValue ?? '';
}

window.Packeta = window.Packeta || {};

interface ICheckoutInputs {
  firstName: string,
  lastName: string,
  billingFirstName: string,
  billingLastName: string,
  email: string,
  phone: string,
  companyName?: string,
  billingCompanyName?: string,
  addressLine1: string,
  addressLine2?: string
  city: string,
  country: string,
  postalCode: string,
  billingAddressLine1?: string,
  billingAddressLine2?: string
  billingCity?: string,
  billingCountry?: string,
  billingPostalCode?: string,
  note: string,
  differentBillingAddress: boolean,
  deliveryOption: string,
  pickUpPointId: number,
  carrierId: number,
  discountCode?: string,
  paymentMethod: string,
  agree: boolean,
  register: boolean,
  password: string,
  username: string,
  newsletter: boolean,
  ico: string,
  dic: string,
}

// TODO: Localize errors
const checkoutSchema = yup.object({
  firstName: yup
    .string()
    .required("firstNameRequired"),
  differentBillingAddress: yup
    .boolean()
    .default(false),
  billingFirstName: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .required("firstNameRequired")
    }),
  billingLastName: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup.string().required("lastNameRequired")
    }),
  lastName: yup
    .string()
    .required("lastNameRequired"),
  email: yup
    .string()
    .email("emailWrongFormat")
    .required("emailRequired"),
  phone: yup
    .string()
    .trim()
    .transform((value: string | undefined | null) => {
      if (!value) {
        return null;
      }
      return value.split(" ").join("");
    })
    .required("phoneRequired")
    .matches(  // TODO
      /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
      "phoneWrongFormat")
    .typeError("phoneRequired"),
  billingCompanyName: yup
    .string()
    .optional(),
  companyName: yup
    .string()
    .optional(),
  addressLine1: yup
    .string()
    .required("addressRequired"),
  addressLine2: yup
    .string()
    .optional(),
  city: yup
    .string()
    .required("cityRequired"),
  country: yup
    .string()
    .required("countryRequired"),
  postalCode: yup
    .string()
    .required("zipCodeRequired"),
  billingAddressLine1: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .required("addressRequired")
    }),
  billingAddressLine2: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .optional()
    }),
  billingCity: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .required("cityRequired")
    }),
  billingCountry: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .required("countryRequired")
    }),
  billingPostalCode: yup
    .string()
    .when('differentBillingAddress', {
      is: true,
      then: yup
        .string()
        .required("zipCodeRequired")
    }),
  note: yup
    .string()
    .optional(),
  deliveryOption: yup
    .string()
    // .default(Object.keys(deliveryOptionsConstants)[0])
    // .oneOf(Object.keys(deliveryOptionsConstants), "invalidShippingMethod")
    .oneOf(Array.from(deliveryMethodKeys), "invalidShippingMethod")
    .required("shippingMethodRequired"),
  carrierId: yup
    .number()
    .when('deliveryOption', {
      is: 'packetaAddressDelivery',
      then: yup
        .number()
        .required("shippingMethodRequired")
    }),
  pickUpPointId: yup
    .string()
    .when('deliveryOption', {
      is: 'packetaPickupPoint',
      then: yup
        .string()
        .required("shippingMethodRequired")
    }),
  discountCode: yup
    .string()
    .optional(),
  paymentMethod: yup
    .string()
    // .default(Object.keys(paymentMethods)[0])
    // .oneOf(Object.keys(paymentMethods), "invalidPaymentMethod")
    .oneOf(Array.from(paymentMethodKeys), "invalidPaymentMethod")
    .required("paymentMethodRequired"),
  agree: yup
    .boolean()
    .oneOf([true], "termsConsentRequired"),
  password: yup
    .string()
    .when('register', {
      is: true,
      then: yup
        .string()
        .min(8, "passwordLengthConstraint")
        .required('passwordRequired')
    }),
  username: yup
    .string()
    .when('register', {
      is: true,
      then: yup
        .string()
        .min(3, "usernameLengthConstraint")
        .required("usernameRequired")
    }),
  newsletter: yup
    .boolean()
    .default(false),
  ico: yup
    .string()
    .matches(/^\d{8}$/, {
      message: "companyIdentificationNumberWrongFormat",
      excludeEmptyString: true,
    })
    .optional(),
  dic: yup
    .string()
    .matches(/^\d{10}$/, {
      message: "companyTaxNumberWrongFormat",
      excludeEmptyString: true,
    })
    .optional(),
}).required("formIncomplete");

const packetaApiKey = '07e7b45636535bce';
const packetaFetcher = (url: string) => fetch(url).then(res => res.json());
const packetaUrl = `https://www.zasilkovna.cz/api/v4/${packetaApiKey}/branch.json?lang=sk`;

const cashOnDeliveryFetcher = async (url: string) => {
  try {
    const response = await axios.get(url);
    console.log(response.data.data?.attributes.cashOnDelivery);
    return response.data.data?.attributes.cashOnDelivery ?? 0;
  } catch (e) {
    return 0;
  }
}

// const deliveryFeeFetcher = async (url: string) => {
//   try {
//     const response = await axios.get(url);
//     const prices = _.omit(response.data.data?.attributes, ['createdAt', 'updatedAt']);
//     // const codFee: number = prices.cashOnDelivery ?? 0;
//     // console.log(codFee)
//     const deliveryOptions: { [keys: string]: DeliveryOption } = {};

//     Object.entries(deliveryOptionsConstants).map(([key, value]: [string, DeliveryOption]) => {
//       deliveryOptions[key] = value;
//       deliveryOptions[key].price = prices[value.fieldName];
//     });
//     // return { codFee, deliveryOptions };
//     return deliveryOptions;
//   } catch (e) {
//     // return { codFee: 0, deliveryOptions: {} };
//     return {};
//   }
// }

const getCashback = (price: number, cashbackLevels?: { amountOff: number, priceAtMost: number }[]) => {
  if (!cashbackLevels) {
    return 0;
  }
  const { amountOff } = cashbackLevels.find(
    ({ priceAtMost }) => priceAtMost && price <= priceAtMost)
    || cashbackLevels[cashbackLevels.length - 1]
    || { amountOff: 0 };
  return amountOff;
}

const CheckoutForm = () => {
  const user = useCurrentUser();
  const { dispatch: dispatchUser } = useDispatchCurrentUser();
  const { host } = window.location;
  const { data: packetaData } = useSWR(packetaUrl, packetaFetcher);
  const [filledData, setFilledData] = useState(false);
  const [differentBillingAddress, setDifferentBillingAddress] = useState(false);
  const [registerModal, setRegisterModal] = useState(true);

  const [coupon, setCoupon] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pickupPointName, setPickupPointName] = useState('');
  let carriers: { id: number, labelName: string, country: string }[] = [];

  const { cartItems, totalPrice, locale } = useStateContext();

  const pageFetcher: Fetcher<CheckoutPage> = (url: string) => axios.get(url).then(res => ({
    ...res.data.data.attributes,
    id: res.data.data.id
  }));
  const {
    data: page,
    // isLoading: pageIsLoading
  } = useSWR<CheckoutPage>(
    localizedUrl('checkout-page', {
      'populate': '*',
    }, locale),
    pageFetcher
  );

  const cashbackFetcher: Fetcher<[{
    amountOff: number,
    priceAtMost: number
  }]> = (url: string) => axios.get(url).then(
    res => res.data.data?.map(
      ({
        attributes: {
          amountOff,
          priceAtMost
        }
      }: { attributes: CashbackLevel }) => ({
        amountOff,
        priceAtMost
      })));
  const { data: cashbackLevels } = useSWR<[{
    amountOff: number,
    priceAtMost: number
  }]>(
    localizedUrl('cashback-levels', {
      "sort": "priceAtMost:asc",
    }, locale),
    cashbackFetcher
  );

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ICheckoutInputs>({
    resolver: yupResolver(checkoutSchema),
    defaultValues: {
      newsletter: user.state.user?.newsletter ?? false,
    }
  });

  const methodsFetcher = <T,>(pickEnabled: boolean) => async (url: string): Promise<Partial<T>> => {
    try {
      const response = await axios.get(url);
      if (!response.data.data?.attributes) {
        return {};
      }

      const allMethods = _.omit(response.data.data?.attributes, [
        'createdAt', 'locale', 'localizations', 'updatedAt'
      ]);

      if (!pickEnabled) {
        return allMethods as Partial<T>;
      }
      return _.pickBy(
        allMethods,
        (v: any, k: string) => v.enabled
      ) as Partial<T>;
    } catch (e) {
      return {};
    }
  }

  // const { data: deliveryMethodsLocale } = useSWR<Partial<DeliveryMethods>>(
  //   localizedUrl('delivery-method', {
  //     'populate': '*'
  //   }, locale || DEFAULT_LOCALE),
  //   methodsFetcher<DeliveryMethods>(false)
  // ) as { data: Partial<DeliveryMethods> };

  const { data: deliveryMethods } = useSWR<Partial<DeliveryMethods>>(
    localizedUrl('delivery-method', {
      'populate': '*'
      // }, watch('country') ? getDefaultLocaleFromCountry(watch('country')) : (locale || DEFAULT_LOCALE)),
    }, locale || DEFAULT_LOCALE),
    methodsFetcher<DeliveryMethods>(true)
  ) as { data: Partial<DeliveryMethods> };

  const { data: paymentMethods } = useSWR<Partial<PaymentMethods>>(
    localizedUrl('payment-method', {
      'populate': '*'
    }, locale || DEFAULT_LOCALE),
    methodsFetcher<PaymentMethods>(true)
  ) as { data: Partial<PaymentMethods> };

  const [currency, setCurrency] = useState<Currency>(getCurrencyFromLocale(DEFAULT_LOCALE));
  useEffect(() => {
    if (locale) {
      setCurrency(getCurrencyFromLocale(locale));
    }
  }, [locale]);

  const packetaOptions = {
    language: "sk",
    valueFormat: "\"Packeta\",id,carrierId,carrierPickupPointId,name,city,street"
  };

  const openPacketa = () => {
    window.Packeta.Widget.pick(packetaApiKey, showSelectedPickupPoint, packetaOptions);
  }


  const fillUsersData = () => {
    console.log(JSON.stringify(user.state.user, null, 2));
    setValue('firstName', user.state.user?.firstName ?? '');
    setValue('lastName', user.state.user?.lastName ?? '');
    setValue('email', user.state.user?.email ?? '');
    setValue('phone', user.state.user?.phone ?? '');
    // setValue('country', user.state.user?.shippingAddress?.country ?? '');
    // setValue('companyName', firstOrDefault(user.state.user?.shippingAddress, 'company'));
    // setValue('country', firstOrDefault(user.state.user?.shippingAddress, 'country'));
    // setValue('postalCode', firstOrDefault(user.state.user?.shippingAddress, 'postalCode'));
    // setValue('city', firstOrDefault(user.state.user?.shippingAddress, 'city'));
    // setValue('addressLine1', firstOrDefault(user.state.user?.shippingAddress, 'addressLine1'));
    // setValue('addressLine2', firstOrDefault(user.state.user?.shippingAddress, 'addressLine2'));

    // setDifferentBillingAddress(user.state.user?.billingAddress !== null);
    // console.log(user.state.user?.billingAddress !== null);

    // if (user.state.user?.billingAddress !== null) {
    setValue('differentBillingAddress',
      (user.state.user?.billingAddress !== null)
      && !_.isEqual(user.state.user?.shippingAddress, user.state.user?.billingAddress)
      && (user.state.user?.billingAddress?.addressLine1 !== null)
      && (user.state.user?.billingAddress?.addressLine1 !== '')
    );

    if (getValues('differentBillingAddress')) {
      setValue('billingFirstName', user.state.user?.firstName ?? '');
      setValue('billingLastName', user.state.user?.lastName ?? '');
      //setValue('billingCompanyName', user.state.user?.billingAddress?.company ?? '')
      setValue('billingCountry', user.state.user?.billingAddress?.country ?? '');
      setValue('billingPostalCode', user.state.user?.billingAddress?.postalCode ?? '');
      setValue('billingCity', user.state.user?.billingAddress?.city ?? '');
      setValue('billingAddressLine1', user.state.user?.billingAddress?.addressLine1 ?? '');
      setValue('billingAddressLine2', user.state.user?.billingAddress?.addressLine2 ?? '');
    } else {
      ;
    }
    setFilledData(true);
  }

  const _nameToCarrierId = (name: string) => {
    const carrier: any = carriers?.find((i: any) => i.labelName === name);
    return carrier?.id;
  }

  const validateCoupon = () => {
    axios.get(`discount-code/validate/${getValues('discountCode')}`)
      .then(res => {
        if (res.status === 200) {
          setCoupon(res.data.data.percentOff);
        } else {
          toast.error(page?.checkoutErrors.invalidDiscountCode || "Invalid discount code");
        }
      })
      .catch(_ => toast.error(page?.checkoutErrors.invalidDiscountCode || "Invalid discount code"));
  }

  if (packetaData) {
    carriers = Object.entries(packetaData.carriers).map(x => x[1]) as { id: number, labelName: string, country: string }[];
  }

  const onSubmit = async (data: ICheckoutInputs) => {
    if (!(getValues('deliveryOption') in deliveryMethods)) {
      toast.error(page?.checkoutErrors.invalidShippingMethod
        || "Invalid shipping method");
      return;
    }
    if (!(getValues('paymentMethod') in paymentMethods)) {
      toast.error(page?.checkoutErrors.invalidPaymentMethod
        || "Invalid payment method");
      return;
    }

    const deliveryMethodKey = getValues('deliveryOption') as DeliveryMethodKey;
    const deliveryMethod = deliveryMethods[deliveryMethodKey] as DeliveryMethod;
    const paymentMethodKey = getValues('paymentMethod') as PaymentMethodKey;
    const paymentMethod = paymentMethods[paymentMethodKey] as PaymentMethod;

    setLoading(true);
    if (getValues('register')) {
      let registered = true;
      const userData = _.pick(data, ["username", "firstName", "lastName", "email", "password", "phone"]);
      await registerUser(
        userData,
        (response) => {
          dispatchUser({
            type: UserAction.Login,
            user: response.data.user as User
          });
        },
        (error?: Error) => {
          toast.error(
            `${page?.checkoutErrors.failedToCreateAccount
            || "Failed to create account"} ${error?.message}`
          );
          registered = false;
        }
      )

      if (!registered) {
        setLoading(false);
        return;
      }
    }

    const cart = cartItems.map(item => ({
      product: { id: item.id },
      quantity: item.quantity
    }));

    const order: Record<string, any> = {
      cashOnDelivery: paymentMethod.type === 'cashOnDelivery',
      paymentMethod: paymentMethod.backendKey,
      cart: cart,
      customerInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        company: data.companyName,
        newsletter: data.newsletter,
        ico: data.ico,
        dic: data.dic,
      },
      billingAddress: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        country: countries[data.country as Country],
        city: data.city,
        postalCode: data.postalCode,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.companyName,
      },
      currency: currency.code, // TODO
      discountCode: data.discountCode,
    };

    if (data.differentBillingAddress) {
      order.billingAddress = {
        addressLine1: data.billingAddressLine1,
        addressLine2: data.billingAddressLine2,
        country: data.billingCountry,
        city: data.billingCity,
        postalCode: data.billingPostalCode,
        firstName: data.billingFirstName,
        lastName: data.billingLastName,
        company: data.billingCompanyName,
      }
    }
    // if (!(deliveryOption in deliveryOptions)) {
    //     toast.error("Nastala chyba, skúste to znova");
    //     setLoading(false);
    //     return;
    // }

    if (deliveryMethodKey === "packetaPickupPoint") {
      order.shipping = [
        {
          // __component: deliveryOptionsConstants[deliveryOption].__component,
          __component: deliveryMethod.component,
          addressId: getValues('pickUpPointId'),
          // carrier: deliveryOptionsConstants[deliveryOption].carrier,
          carrier: deliveryMethod.carrier,
        }
      ]
    } else {
      order.shipping = [
        {
          // __component: deliveryOptionsConstants[deliveryOption].__component,
          __component: deliveryMethod.component,
          address: {
            city: data.city,
            country: countries[data.country as Country],
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            firstName: data.firstName,
            lastName: data.lastName,
            postalCode: data.postalCode,
            company: data.companyName,

          },
          carrierId: getValues('carrierId') ?? -1,
          // carrier: deliveryOptionsConstants[deliveryOption].carrier,
          carrier: deliveryMethod.carrier,
        }
      ]
    }

    axios.post('/orders', { data: { order } },)
      .then(d => {
        setLoading(false);
        window.location = d.data.data.redirectUri;
      })
      .catch(e => {
        console.log(e.response.data);
        toast.error(
          page?.checkoutErrors.failedToCreateOrder
          || 'Failed to create order. Check the entered information.'
        );
        setLoading(false);
      });
  }

  const showSelectedPickupPoint = (point: any) => {
    setPickupPointName(point.name);
    setValue('pickUpPointId', point.id)
  }

  if (!page) {
    return <Loader />;
  }

  const errorTexts = page.checkoutErrors;

  return (
    <div className='container-fluid me-auto ml-auto'>
      <div className='row g-0'>
        <h1 className='mb-3 col-12'>{page.title}</h1>

        {/* <div className='step'>
                            <span className='fw-bold'>Iné fakturačné údaje?</span>
                            <input className='step-check' type="checkbox" id="changeDeliver"
                                {...register('differentBillingAddress')}
                            />
                        </div> */}

        <form className='row g-0 d-flex justify-content-between'
          onSubmit={handleSubmit(onSubmit)}>
          <div className='col-12 col-md-6 mb-3'>

            {(user.state.user)
              ? (
                <button type='button' className='btn --primary mb-2 filling-btn'
                  onClick={() => {
                    fillUsersData();
                  }
                  }
                >
                  {page.useSavedInfo}
                </button>
              )
              : <>
                <div className='step mb-3 justify-content-between'>
                  <span className='fw-bold'>
                    {page.registrationNotice}&nbsp;
                    <Tippy content={
                      <div
                        className='row g-0 d-flex registration-popup'
                        dangerouslySetInnerHTML={{ __html: page.registrationModal.body }}
                      ></div>
                    }>
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </Tippy>
                  </span>
                  <input className='step-check' type="checkbox"
                    id="register" {...register('register')} />
                </div>

                {registerModal && (
                  <Modal
                    title={page.registrationModal.title}
                    close={() => {
                      setRegisterModal(false);
                    }}
                  >
                    <>
                      <div
                        className='row g-0 d-flex'
                        dangerouslySetInnerHTML={{ __html: page.registrationModal.body }}
                      >
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center justify-content-sm-between gap-3 flex-sm-row">
                        <button
                          type="submit"
                          className="btn --primary"
                          onClick={() => {
                            setValue('register', true);
                            setRegisterModal(false);
                          }}
                        >
                          <div>{page.registrationModal.yes}</div>
                        </button>
                        <button
                          type="button"
                          className="btn --primary"
                          onClick={() => {
                            setValue('register', false);
                            setRegisterModal(false);
                          }}
                        >
                          <div>{page.registrationModal.no}</div>
                        </button>
                      </div>
                    </>
                  </Modal>
                )}
              </>
            }



            {watch('register') &&
              <div
                className='d-flex flex-column flex-sm-row align-items- gap-3 mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.username && <p
                    className='text-danger fw-bold mb-1 mt-1 pb-1'>{errors.username?.message && errorTexts[errors.username.message] || errors.username.message}</p>}
                  <label htmlFor="username" className="--required">
                    {page.username}
                  </label>
                  <input type="text" id="username" {...register("username")} />
                </div>
                <div className='d-flex flex-column w-100'>
                  {errors.password && <p
                    className='text-danger fw-bold mb-1 mt-1 pb-1'>{`${errors.password?.message && errorTexts[errors.password.message] || errors.password.message}\n`}</p>}
                  <label htmlFor="password" className="--required">
                    {page.password}
                  </label>
                  <input type="password"
                    id="password" {...register("password")} />
                </div>
              </div>
            }

            <div className='step'>
              <h3 className='mb-2'>{page.deliveryAddressTitle}</h3>
            </div>

            <div>
              <div
                className='d-flex flex-column flex-sm-row align-items-center gap-3 mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.firstName && <p
                    className='text-danger fw-bold mb-1'>{errors.firstName?.message && errorTexts[errors.firstName.message] || errors.firstName.message}</p>}
                  <label htmlFor="name" className='--required'>{page.firstName}</label>
                  <input type="text" id="name" {...register("firstName")} />
                </div>

                <div className='d-flex flex-column w-100'>
                  {errors.lastName && <p
                    className='text-danger fw-bold mb-1'>{errors.lastName?.message && errorTexts[errors.lastName.message] || errors.lastName.message}</p>}
                  <label htmlFor="surname"
                    className='--required'>{page.lastName}</label>
                  <input type="text" id="surname" {...register("lastName")} />
                </div>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  <label htmlFor="companyName">
                    {page.company}
                  </label>
                  <input type="text"
                    id="companyName" {...register("companyName")} />
                </div>
              </div>

              {(
                watch("companyName") && !getValues("differentBillingAddress")
              ) &&
                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    {errors.ico && <p
                      className='text-danger fw-bold mb-1'>{errors.ico?.message && errorTexts[errors.ico.message] || errors.ico.message}</p>}
                    <label htmlFor="ico">
                      {page.companyIdentificationNumber}
                    </label>
                    <input type="text"
                      id="ico" {...register("ico")} />
                  </div>
                </div>}


              {(
                watch("companyName") && !getValues("differentBillingAddress")
              ) &&
                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    {errors.dic && <p
                      className='text-danger fw-bold mb-1'>{errors.dic?.message && errorTexts[errors.dic.message] || errors.dic.message}</p>}
                    <label htmlFor="dic">
                      {page.companyTaxNumber}
                    </label>
                    <input type="text"
                      id="dic" {...register("dic")} />
                  </div>
                </div>}

              {((user.state.user?.shippingAddress?.length ?? 0) > 0) &&
                <div className='select-box w-100 mb-2'>
                  {/* {errors.country && <p className='text-danger fw-bold mb-1'>{errors.country?.message && errorTexts[errors.country.message] || errors.country.message}</p>} */}
                  <label htmlFor="" className='--required'>{page.savedAddresses}</label>
                  <SelectBox
                    placeholder={page.addressSearchPlaceholder}
                    selectValues={[page.newAddress, ...user.state.user!.shippingAddress!.map((ad: IDeliveryAdress) => (`${ad.addressLine1}, ${ad.city}`))]}
                    onSelectEvent={
                      (ad: string, index: number) => {
                        if (index === 0) {
                          for (const key of ["country", "city", "addressLine1", "addressLine2", "region", "postalCode", "note"]) {
                            //TODO change maybe to false
                            setValue(key as keyof ICheckoutInputs, '', { shouldValidate: false })
                          }
                          return;
                        }
                        const address = user.state.user!.shippingAddress![index - 1]
                        Object.keys(address).forEach(key =>
                          setValue(key as keyof ICheckoutInputs, address[key], { shouldValidate: true })
                        )
                      }
                    }
                    defaultValue={''}
                  />
                </div>
              }

              <div className='select-box w-100 mb-2'>
                {errors.country && <p
                  className='text-danger fw-bold mb-1'>{errors.country?.message && errorTexts[errors.country.message] || errors.country.message}</p>}
                <label htmlFor="" className='--required'>{page.country}</label>
                <SelectBox
                  placeholder={page.addressSearchPlaceholder}
                  // selectValues={Object.values(countries)}
                  selectItems={Object.entries(countries).map(([k, v]) => ({ label: v, value: k }))}
                  onSelectEvent={(l, i, c) => setValue('country', c, { shouldValidate: true })}
                  defaultValue={watch('country') ?? ''}
                />
              </div>

              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.addressLine1 && <p
                    className='text-danger fw-bold mb-1'>{errors.addressLine1?.message && errorTexts[errors.addressLine1.message] || errors.addressLine1.message}</p>}
                  <label htmlFor="street" className='--required'>{page.street}</label>
                  <input className='mb-1' type="text"
                    placeholder={page.addressLine1Placeholder} {...register("addressLine1")} />
                  <input type="text" {...register("addressLine2")}
                    placeholder={page.addressLine2Placeholder} />
                </div>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.postalCode && <p
                    className='text-danger fw-bold mb-1'>{errors.postalCode?.message && errorTexts[errors.postalCode.message] || errors.postalCode.message}</p>}
                  <label htmlFor="postalCode" className='--required'>{page.zipCode}</label>
                  <input type="text" {...register("postalCode")}
                    id="postalCode" />
                </div>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.city && <p
                    className='text-danger fw-bold mb-1'>{errors.city?.message && errorTexts[errors.city.message] || errors.city.message}</p>}
                  <label htmlFor="city" className='--required'>{page.city}</label>
                  <input type="text" {...register("city")} id="city" />
                </div>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.phone && <p
                    className='text-danger fw-bold mb-1'>{errors.phone?.message && errorTexts[errors.phone.message] || errors.phone.message}</p>}
                  {/* {errors.phone && <p className='text-danger fw-bold mb-1'>Vyplň dané políčko.</p>} */}
                  <label htmlFor="phone" className='--required'>{page.phoneNumber}</label>
                  <input type="text" {...register("phone")} id="phone"
                    placeholder="+421 940 123 456" />
                </div>
              </div>

              <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-column w-100'>
                  {errors.email && <p
                    className='text-danger fw-bold mb-1'>{errors.email?.message && errorTexts[errors.email.message] || errors.email.message}</p>}
                  <label htmlFor="email" className='--required'>{page.emailAddress}</label>
                  <input type="text" {...register("email")} id="email" />
                </div>
              </div>
            </div>

            <div className='step'>
              <span className='fw-bold'>{page.differentBillingAddressButton}</span>
              <input className='step-check' type="checkbox" id="changeDeliver"
                {...register('differentBillingAddress')}
              />
            </div>

            {watch('differentBillingAddress') &&
              <div>
                <div
                  className='d-flex flex-column flex-sm-row align-items-center gap-3 mb-2'>
                  <div className='d-flex flex-column w-100'>
                    {errors.billingFirstName && <p
                      className='text-danger fw-bold mb-1'>{errors.billingFirstName?.message && errorTexts[errors.billingFirstName.message] || errors.billingFirstName.message}</p>}
                    <label htmlFor="billingName" className='--required'>{page.firstName}</label>
                    <input type="text" {...register('billingFirstName')}
                      id="billingName" />
                  </div>
                  <div className='d-flex flex-column w-100'>
                    {errors.billingLastName && <p
                      className='text-danger fw-bold mb-1'>{errors.billingLastName?.message && errorTexts[errors.billingLastName.message] || errors.billingLastName.message}</p>}
                    <label htmlFor="billingLastName"
                      className='--required'>{page.lastName}</label>
                    <input type="text" {...register('billingLastName')}
                      id="billingLastName" />
                  </div>
                </div>

                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    <label htmlFor="billingCompanyName">{page.company}</label>
                    <input type="text" {...register('billingCompanyName')}
                      id="billingCompanyName" />
                  </div>
                </div>

                {watch("billingCompanyName") &&
                  <div className='d-flex align-items-center mb-2'>
                    <div className='d-flex flex-column w-100'>
                      {errors.ico && <p
                        className='text-danger fw-bold mb-1'>{errors.ico?.message && errorTexts[errors.ico.message] || errors.ico.message}</p>}
                      <label htmlFor="ico">
                        {page.companyIdentificationNumber}
                      </label>
                      <input type="text"
                        id="ico" {...register("ico")} />
                    </div>
                  </div>}


                {watch("billingCompanyName") &&
                  <div className='d-flex align-items-center mb-2'>
                    <div className='d-flex flex-column w-100'>
                      {errors.dic && <p
                        className='text-danger fw-bold mb-1'>{errors.dic?.message && errorTexts[errors.dic.message] || errors.dic.message}</p>}
                      <label htmlFor="dic">
                        {page.companyTaxNumber}
                      </label>
                      <input type="text"
                        id="dic" {...register("dic")} />
                    </div>
                  </div>
                }

                <div className='select-box w-100 mb-2'>
                  {errors.billingCountry && <p
                    className='text-danger fw-bold mb-1'>{errors.billingCountry?.message && errorTexts[errors.billingCountry.message] || errors.billingCountry.message}</p>}
                  <label htmlFor="" className='--required'>{page.country}</label>
                  <SelectBox
                    placeholder={page.addressSearchPlaceholder}
                    selectValues={Object.values(countries)}
                    onSelectEvent={(c: string) => setValue('billingCountry', c, { shouldValidate: true })}
                    defaultValue={watch('billingCountry') ?? ''}
                  />
                </div>

                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    {errors.billingAddressLine1 && <p
                      className='text-danger fw-bold mb-1'>{errors.billingAddressLine1?.message && errorTexts[errors.billingAddressLine1.message] || errors.billingAddressLine1.message}</p>}
                    <label htmlFor="billingAddressLine1"
                      className='--required'>{page.street}</label>
                    <input className='mb-1'
                      type="text" {...register('billingAddressLine1')}
                      id="billingAddressLine1"
                      placeholder={page.addressLine1Placeholder} />
                    <input type="text" {...register('billingAddressLine2')}
                      id="billingAddressLine2"
                      placeholder={page.addressLine2Placeholder} />
                  </div>
                </div>
                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    {errors.billingPostalCode && <p
                      className='text-danger fw-bold mb-1'>{errors.billingPostalCode?.message && errorTexts[errors.billingPostalCode.message] || errors.billingPostalCode.message}</p>}
                    <label htmlFor="billingPostalCode"
                      className='--required'>{page.zipCode}</label>
                    <input type="text" {...register('billingPostalCode')}
                      id="billingPostalCode" />
                  </div>
                </div>

                <div className='d-flex align-items-center mb-2'>
                  <div className='d-flex flex-column w-100'>
                    {errors.billingCity && <p
                      className='text-danger fw-bold mb-1'>{errors.billingCity?.message && errorTexts[errors.billingCity.message] || errors.billingCity.message}</p>}
                    <label htmlFor="billingCity"
                      className='--required'>{page.city}</label>
                    <input type="text" {...register('billingCity')}
                      id="billingCity" />
                  </div>
                </div>
              </div>
            }


            <div className='d-flex align-items-center mb-2'>
              <div className='d-flex flex-column w-100'>
                <label htmlFor="note">{page.orderNotes}</label>
                <textarea {...register('note')} id="note" />
              </div>
            </div>
          </div>

          <aside className="col-12 col-md-5 layout-cart mb-3">
            <header className='layout-cart__header'>
              <h1 className='header-heading'>{page.summaryTitle}</h1>
            </header>

            <div className='cart-products mb-1'>
              {cartItems.map(item => {
                return (
                  <div key={item.id} className='cart-product mb-1'>
                    <div className='d-flex align-items-center gap-2'>
                      <img
                        src={'https://api.' + host + item.mainImage.data.attributes.url}
                        alt="asd" />
                      <span>{item.quantity}x {item.name}</span>
                    </div>
                    <span>{item.price} {currency.sign}</span>
                  </div>
                )
              })}
            </div>

            <div className="cart-info mb-1">
              <div
                className='d-flex justify-content-between align-items-center mb-1'>
                <span>{page.subtotal}</span>
                <span>{totalPrice} {currency.sign}</span>
              </div>
              {user.state.user && getCashback(totalPrice, cashbackLevels) > 0 &&
                <div
                  className='d-flex justify-content-between align-items-center mb-1'>
                  <span>Cashback</span>
                  <span>-{getCashback(totalPrice, cashbackLevels)} {currency.sign}</span>
                </div>
              }
              <div className='mb-1'>
                <span className='fw-bold'>{page.shipping}</span>
                {!watch('country')
                  ? (
                    <div><span className='text-danger fw-bold'>
                      {page.checkoutErrors.countryRequired}
                    </span></div>
                  ) : (
                    deliveryMethods && Object.entries(deliveryMethods).map(
                      ([dmKey, dm]: [string, DeliveryMethod]) => (
                        <div
                          key={dm.id}
                          className='d-flex align-items-center justify-content-between delivery-option'
                        >
                          <label htmlFor={dmKey}>
                            {dm.name}: {dm.fee} {currency.sign}
                          </label>
                          <input
                            type="radio"
                            id={dmKey}
                            value={dmKey}
                            {...register('deliveryOption')}
                          />
                        </div>
                      )
                    )
                  )}
              </div>

              {watch('deliveryOption') === 'packetaPickupPoint' &&
                <div>
                  {errors.pickUpPointId && <p
                    className='text-danger fw-bold mb-1'>{errors.pickUpPointId?.message && errorTexts[errors.pickUpPointId.message] || errors.pickUpPointId.message}</p>}
                  <div className='packeta-wrapper'>
                    {!watch('pickUpPointId') &&
                      <img src={packetaLogo} alt="packeta" />}
                    {watch('pickUpPointId') && <span
                      className='bg-primary fs-5 text-white fw-bold p-1'>{pickupPointName}</span>}
                    <button type='button' className="btn --packeta"
                      onClick={openPacketa}>
                      {!watch('pickUpPointId') && page.selectPickupPoint}
                      {watch('pickUpPointId') && page.selectPickupPoint}
                    </button>
                  </div>
                </div>
              }

              {watch('deliveryOption') === 'packetaAddressDelivery' &&
                <div className='select-box w-100 mb-2'>
                  {errors.carrierId && <p
                    className='text-danger fw-bold mb-1'>{errors.carrierId?.message && errorTexts[errors.carrierId.message] || errors.carrierId.message}</p>}
                  <label htmlFor="" className='--required'>{page.courier}</label>
                  <SelectBox placeholder={page.addressSearchPlaceholder}
                    selectValues={carriers.filter(c => !getValues("country") || (c.country === getValues("country"))).map(i => i.labelName)}
                    onSelectEvent={(c: string) => setValue('carrierId', _nameToCarrierId(c), { shouldValidate: true })}
                  />
                </div>
              }

              <div className='mb-2'>
                <span className='fw-bold'>{page.paymentMethod}</span>
                {paymentMethods && Object
                  .entries(paymentMethods)
                  .map(([pmKey, pm]: [string, PaymentMethod]) => {
                    const label = pm.name
                      + (pm.fee ? ` (+${pm.fee} ${currency.sign})` : '');
                    return (
                      <div
                        key={pmKey}
                        className='d-flex align-items-center justify-content-between delivery-option'
                      >
                        <label htmlFor={pmKey}>{label}</label>
                        <input
                          type="radio"
                          id={pmKey}
                          value={pmKey}
                          {...register('paymentMethod')}
                        />
                      </div>
                    );
                  })}
              </div>

              <div className="mb-1">
                <span className='fw-bold'>{page.discountCode}</span>
                {coupon === 0 && <div className='d-flex flex-column gap-1 mb-2'>
                  <input type="text"
                    placeholder={page.discountCodePlaceholder} {...register('discountCode')} />
                  <button type='button'
                    className='btn --coupon'
                    onClick={() => validateCoupon()}
                  >
                    {page.discountCodeButton}
                  </button>
                </div>}
                {coupon !== 0 && <div className='coupon-holder'>
                  <span>{page.discount}</span>
                  <span>{coupon}%</span>
                </div>}
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between mb-2">
              <span className='fs-3'>{page.total}</span>
              <span className='fw-bold fs-2'>{
                totalPrice
                - (user.state.user ? getCashback(totalPrice, cashbackLevels) : 0)
                - (user.state.user ? totalPrice - getCashback(totalPrice, cashbackLevels) : 0) * coupon / 100
                // + (deliveryOptions?.[watch('deliveryOption')].price ?? 0)
                + (deliveryMethods?.[watch('deliveryOption') as DeliveryMethodKey]?.fee ?? 0)
                + (paymentMethods?.[watch('paymentMethod') as PaymentMethodKey]?.fee ?? 0)} {currency.sign}
              </span>
            </div>

            <p className='fs-5 mb-2'>
              {page.personalDataNotice}
            </p>

            {/* <div className=' gap-1 align-items-center mb-2'>
              {errors.agree && <p
                className='text-danger fw-bold mb-1'>{errors.agree?.message && errorTexts[errors.agree.message] || errors.agree.message}</p>}

              <input className='checkbox-agree'
                type="checkbox" {...register('agree')} id="" />
              <span className='ml-1 fs-5 --required'>Prečítal/a som si všeobecné obchodné podmienky a súhlasím s nimi</span>
            </div> */}


            <div className="mb-2">
              {errors.agree && <p
                className='text-danger fw-bold mb-1'>{errors.agree?.message && errorTexts[errors.agree.message] || errors.agree.message}</p>}
              <div className="d-flex align-items-center gap-1">
                <input className="checkbox-agree"
                  type="checkbox"  {...register('agree')} id="" />
                <span className="fs-5 --required">{page.termsConsentCheckbox}
                </span>
              </div>
            </div>

            {/* {user.state.user?.newsletter || */}
            <div className={'gap-1 align-items-center mb-2 '
              + ((user.state.user?.newsletter) ? 'd-none' : 'd-flex')}>
              {errors.newsletter && <p
                className='text-danger fw-bold mb-1'>{errors.newsletter?.message && errorTexts[errors.newsletter.message] || errors.newsletter.message}</p>}
              <input className='checkbox-agree'
                type={(user.state.user?.newsletter) ? "hidden" : "checkbox"} {...register('newsletter')}
                id="checkbox-agree" />
              <span
                className='fs-5'>{page.marketingConsentCheckbox}
              </span>
            </div>
            {/* } */}

            <button type='submit' className='btn --tertiary mx-auto'
              disabled={loading}>
              {!loading && page.orderButton}
              {loading && <Spinner />}
            </button>
          </aside>

        </form>
      </div>
    </div>
  );
}

export default CheckoutForm

import React, {FC} from 'react';
import ReactMarkdown from "react-markdown";
import axios from "axios";
import useSWR from "swr";
import { localizedUrl } from '../../utils/locale';
import { useStateContext } from '../../context/StateContext';

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes.text);

interface Props {
    url: string
}
const Paperwork: FC<Props> = ({url}) => {
    const { locale } = useStateContext();
    const { data } = useSWR<string>(localizedUrl(url, {}, locale), fetcher);

    return (
        <div className='container-fluid'>
            <div className='row'>
            <div dangerouslySetInnerHTML={{ __html: data ?? ''}} />
                {/* <ReactMarkdown>
                    {data ?? ''}
                </ReactMarkdown> */}
            </div>
        </div>
    );
};

export default Paperwork;

import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import './CategoryCard.scss';

interface IProps {
    title: string;
    spec: string;
    desc: string;
    to: string;
    img: string;
}

const CategoryCard: FC<IProps> = ({ title, spec, desc, to, img}) => {
    return (
        <Link className="category-card col-8 col-sm-4 col-md-2" data-anim="scale-in" to={to}>
            <div className={`img-wrapper --${title}`}>
                <img className="category-img" src={img} alt="bottle" />
            </div>
            <h3 className={`category-title --${title} mb-3 mt-1`}>
                {title}
            </h3>
            <span className="category-spec">{spec}</span>
            <span className="category-desc">{desc}</span>
        </Link>
    )
}

export default CategoryCard
import axios from "axios";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR, { Fetcher } from "swr";
import { AboutPage, Blog } from "../../modals/modals";
import ReactMarkdown from "react-markdown";
// import './BlogDetail.scss';
import '../Blog/BlogDetail.scss'
import { transformUrl as apiUrl } from "../../utils/fetch";
import { useStateContext } from "../../context/StateContext";
import { localizedUrl } from "../../utils/locale";

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);


const About = () => {
  const { host } = window.location;

  const { locale } = useStateContext();
  const url = localizedUrl('about', { 'populate[rows][populate]': '*' }, locale);

  const { data } = useSWR<AboutPage>(url, fetcher);


  return (
    <div className="BlogDetail">
      <div className="row">
        <div className="container-fluid">
          <h3 className="mb-5 text-center text-md-left">{data?.title}</h3>
          {/* <div dangerouslySetInnerHTML={{ __html: data ? data.body : '' }} /> */}
          {data?.rows.map((row, index) => {
            const { id, title, image, text } = row;
            const textClass = 'd-flex flex-column align-items-center order-1 px-5'
              + (image.data ? ' col-12 col-md-6' : ' col-12')
              + (index % 2 === 0 ? ' order-md-1 text-md-start ps-md-5 pe-md-0' : ' order-md-0 text-md-end pe-md-5 ps-md-0');
            const imageClass = 'order-0 mb-3 mb-md-0 d-flex align-items-center'
              + (image.data ? ' col-10 col-md-6' : ' col-12')
              + (index % 2 === 0 ? ' order-md-0' : ' order-md-1');
            
            

            const imageCol = (
              <div className={imageClass}>
                {image.data
                  ? <img src={apiUrl(image.data.attributes.url, host)} className="img-fluid d-block" />
                  : null}
              </div>
            )
            const textCol = (
              <div className={textClass}>
                <h4
                  className={"mb-2 mb-md-3 text-center " + (index % 2 === 0 ? "text-md-start" : "text-md-end")}
                >
                  {title}
                </h4>
                <div
                  className="fs-2"
                  dangerouslySetInnerHTML={{ __html: data ? text : '' }}
                />
              </div>
            )
            return (
              <div key={id} className="row d-flex align-items-center mb-5 justify-content-center">
                {index % 2 === 0 ? imageCol : textCol}
                {index % 2 === 0 ? textCol : imageCol}
              </div>
            )
          })}
          <div className="text-center row mb-6">
           <div className="col-12">
             <p className="fs-1 w-100">
              {data?.footerText} {' '}
               <a
                 className="text-primary"
                 href={data?.footerLink}
               >
                 cbdpredajna.sk
               </a>.
             </p>
           </div>
         </div>
        </div>
        
      </div>
    </div>
  );
}

export default About
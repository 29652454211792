import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, FC } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatchCurrentUser } from "../../context/UserContext";
import { LoginPage, User } from "../../modals/modals";
import toast from "react-hot-toast";
import { UserAction } from "../../enums/enums";
import { useStateContext } from "../../context/StateContext";
import Modal from "./Modal";
import Spinner from "../Spinner/Spinner";
import { localizedUrl } from "../../utils/locale";
import useSWR from "swr";
import Loader from "../Loader/Loader";

interface ILoginInputs {
    identifier: string,
    password: string,
}

const loginSchema = yup.object({
    identifier: yup.string().required(),
    password: yup.string().required(),
}).required();

interface IProps {
    message: string | null,
}

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);

export const LoginModal: FC<IProps> = ({ message }) => {

    const { locale } = useStateContext();
    const url = localizedUrl('login-page', { 'populate': '*' }, locale);

    const { data } = useSWR<LoginPage>(url, fetcher);

    const { setLoginModal, setRegisterModal, setForgotPasswordModal } = useStateContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();


    const { dispatch } = useDispatchCurrentUser();

    const { register, handleSubmit, formState: { errors } } = useForm<ILoginInputs>({
        resolver: yupResolver(loginSchema)
    });
    const onSubmit = (loginData: ILoginInputs) => {
        setLoading(true);
        axios.post('/auth/local', loginData)
            .then(d => {
                if (d.status === 200) {
                    dispatch({ type: UserAction.Login, user: d.data.user as User });
                    setLoginModal(false);
                    navigate('/moj-ucet');
                    setLoading(false);
                } else {
                    toast.error(data?.toastErrorLabel || "Error")
                    setLoading(false);
                }
            })
            .catch(e => {
                toast.error(data?.toastErrorLabel || "Error")
                setLoading(false);
            });
    };

    if (!data) {
        return <Loader />
    }


    return (
        <Modal title={data.loginTitleLabel} close={() => {
            searchParams.delete('confirmation');
            setSearchParams(searchParams);
            setLoginModal(false)
        }
        }>
            <span className="d-block text-primary fw-bold fs-2 mt-2">{message}</span>

            <form className="pt-2 pb-4" onSubmit={handleSubmit(onSubmit)}>
                {errors.identifier && <p className="alert" role="alert">{data?.errorUsernameLabel}</p>}
                <div className="mb-3 d-flex flex-column">
                    <label htmlFor="username" className="mb-1 fw-semibold">{data?.usernameLabel}</label>
                    <input {...register("identifier")}
                        type="text" id="username"
                        aria-describedby="emailHelp" />
                </div>
                {errors.password && <p className="alert" role="alert">{data?.errorPasswordLabel}</p>}
                <div className="mb-1 d-flex flex-column">
                    <label htmlFor="password" className="mb-1 fw-semibold">{data?.passwordLabel}</label>
                    <input {...register("password")}
                        type="password"
                        id="password" />
                </div>

                <span className="d-block mb-3 cursor-pointer" onClick={() => {
                    setForgotPasswordModal(true);
                    setLoginModal(false);
                }}>
                    {data?.forgottenPasswordLabel}
                </span>

                <div className="d-flex flex-column align-items-center justify-content-center justify-content-sm-between gap-3 flex-sm-row">
                    <button type="submit" className="btn --primary" disabled={loading}>
                        {!loading && data?.loginButtonLabel}
                        {loading && <Spinner />}
                    </button>
                    <button type="button" className="btn --tertiary" onClick={() => {
                        setLoginModal(false);
                        setRegisterModal(true);
                    }}>
                        <div>{data?.registerButtonLabel}</div>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>

            </form>
        </Modal>
    );
}

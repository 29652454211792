import React from 'react';
import Paperwork from "./Paperwork";

const PrivacyPolicy = () => {
    return (
        <Paperwork url='returns-policy'/>
    );
};

export default PrivacyPolicy;

import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import useSWR, { Fetcher } from 'swr';
import { useStateContext } from '../../context/StateContext';
import { Category, ICartProduct, ProductPage } from '../../modals/modals';
import './ProductDetail.scss';
import { transformUrl as apiUrl } from '../../utils/fetch';
import ConsentModal from '../../components/Modals/ConsentModal';
import { DEFAULT_LOCALE, locales } from '../../assets/constants';
import { Currency, getCurrencyFromLocale, localizedUrl } from '../../utils/locale';
// import Parser from 'html-react-parser';


export const ProductDetail = () => {
  const {
    consentModal,
    incQuantity,
    decQuantity,
    addToCart,
    quantity,
    setQuantity,
    locale,
  } = useStateContext();
  const { page } = useOutletContext<{ page: ProductPage }>();
  const { host } = window.location;
  const navigate = useNavigate();

  useEffect(() => {
    if (setQuantity) {
      setQuantity(1);
    }
  }, [])

  let { productId } = useParams();

  const fetcher: Fetcher<ICartProduct> = (url: string) => axios.get(url).then(res => ({
    ...res.data.data.attributes,
    id: res.data.data.id,
    quantity: 0
  }));
  const { data: product, error } = useSWR<ICartProduct, Error>(
    localizedUrl(`/products/${productId}`, {
      'populate': '*',
    }, locale),
    fetcher
  );
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (locale && product?.locale && locale !== product.locale) {
      const locId = product.localizations.data.find(loc => loc.id)?.id;
      navigate(locId ? `../${locId}` : '..');
    }
  }, [locale, product]);
  const [currency, setCurrency] = useState<Currency>(getCurrencyFromLocale(DEFAULT_LOCALE));
  useEffect(() => {
    if (locale) {
      setCurrency(getCurrencyFromLocale(locale));
    }
  }, [locale]);

  if (!addToCart || !product || !quantity) {
    return <div>{page.productNotFound}</div>;
  }

  return (
    <div className='container-fluid product-detail'>
      {product.productCategories.data.some((y: Category) => y.attributes.ageProtected)
        && consentModal
        && <ConsentModal page={page} />}
      <div className="row justify-content-center justify-content-sm-between pt-10">
        <div className='col-10 col-sm-4'>
          <div className="image-container">
            <img src={apiUrl(product.mainImage.data.attributes.url, host)} className="product-detail-image" />
          </div>
          {/* <div className="small-images-container">
              {image?.map((item, i) => (
                <img
                  key={i}
                  src={item}
                  className={i === index ? 'small-image selected-image' : 'small-image'}
                  onMouseEnter={() => setIndex(i)}
                />
              ))}
            </div> */}
        </div>

        <div className="product-detail-desc col-10 col-sm-8">
          <h4 className='mb-8 lh-base fw-bold'>{product?.name}</h4>
          <div className='row'>
            <div className='col-12 col-sm-6 d-flex flex-column gap-1 mb-3'>
              <span className='fw-bold fs-2'>{page.productAttributes.dosageLabel}: {product.dosage}</span>
              <span className='fw-bold fs-2'>{page.productAttributes.pricePerUseLabel}: {product.pricePerUse} {currency.sign}</span>
              <span className='fw-bold fs-2'>{page.productAttributes.daysOfUseLabel.replace("$", product.DaysOfUse.toString())}</span>
              {(product?.stockQuantity === 0) ? <span className='fw-bold fs-2 mt-3 text-danger'>{page.emptyStock}</span>
                : ((product?.stockQuantity < 6) ? <span className='fw-bold fs-2 mt-3 text-warning'>{page.lowStock}</span>
                  : <span className='fw-bold fs-2 mt-3 text-success'>{page.inStock}</span>)}
            </div>

            <div className='col-12 col-sm-6 position-relative'>
              <span className="product-detail__price-sign">{currency.sign}</span>
              <span className="product-detail__price mb-4">{product?.price}</span>
              <div className="quantity mb-3">
                <span className='quantity__title fs-2'>{page.countInCartLabel}:</span>
                <div className="quantity-desc">
                  <span className="minus" onClick={decQuantity}>-</span>
                  <span className="num">{quantity}</span>
                  <span className="plus" onClick={incQuantity}>+</span>
                </div>
              </div>
              <div className="buttons mb-12">
                <button type="button" disabled={product.stockQuantity === 0} className="btn --primary" onClick={() => {
                  return addToCart(product, quantity, page.productErrors);
                }}>{page.addToCartButton}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-10">
        <div className="col-12">
          <div className='mb-3 product-detail__markdown'>
            <div dangerouslySetInnerHTML={{ __html: product.info }} />

            {/* <ReactMarkdow skipHtml={true}>
                {product.info}
              </ReactMarkdown> */}
          </div>


          <div className='product-detail-info product-detail__markdown'>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
            {/* <ReactMarkdown>
                {product.description}
              </ReactMarkdown> */}
          </div>
        </div>
      </div>
    </div>
  )
}

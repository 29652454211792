import React, { useState } from 'react';
import Modal from "./Modal";
import { useStateContext } from "../../context/StateContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { UserAction } from "../../enums/enums";
import { PasswordResetPage, RegisterPage, User } from "../../modals/modals";
import toast from "react-hot-toast";
import * as yup from "yup";
import { localizedUrl } from '../../utils/locale';
import useSWR from 'swr';
import Loader from '../Loader/Loader';

interface Inputs {
    email: string,
}

const loginSchema = yup.object({
    email: yup.string().email().required(),
}).required();

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);


const ForgotPasswordModal = () => {
    const { setForgotPasswordModal } = useStateContext();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        resolver: yupResolver(loginSchema)
    });

    const { locale } = useStateContext();
    const url = localizedUrl('password-page', { 'populate': '*' }, locale);

    const { data } = useSWR<PasswordResetPage>(url, fetcher);




    const onSubmit = async (inData: Inputs) => {
        setLoading(true)
        try {
            const response = await axios.post('/auth/forgot-password', inData)
            if (response.status !== 200) {
                setLoading(false);
                // toast.error('Nenašiel sa účet s tvojou emailovou adresou')
                toast.error(data?.toastErrorLabel || "Error")
                return;
            }
            setLoading(false)
            setSuccess(true)
        } catch (e) {
            setLoading(false);
            // toast.error('Nenašiel sa účet s tvojou emailovou adresou');
            toast.error(data?.toastErrorLabel || "Error")
        }
    }
    //         .then(d => {
    //             if (d.status === 200) {
    //                 setSuccess(true);
    //             }
    //         })
    // .catch(e => toast.error('Nenašiel sa účet s tvojou emailovou adresou'));
    //     };

    if (!data) {
        return <Loader />
    }

    return (
        <Modal title={data.passwordResetTitleLabel} close={setForgotPasswordModal}>
            <div className='forgot-modal'>
                {!success && <form className="py-1" onSubmit={handleSubmit(onSubmit)}>
                    <p className='mb-1'>{data.textLabel}</p>
                    {errors.email?.type === 'required' && <p className="alert" role="alert">{data.emailErrorRequiredLabel}</p>}
                    {errors.email?.type === 'email' && <p className="alert" role="alert">{data.emailErrorWrongFormatLabel}</p>}
                    <label htmlFor="email">{data.emailLabel}</label>
                    <input className='mb-2' type="text" id='email' {...register('email')} />
                    <button type='submit' className='btn --primary'>{data.buttonLabel}</button>
                </form>}

                {success && <div className='success-div py-4'>
                    <h3 className='mb-2'>{data.successTitleLabel}</h3>
                    <p>{data.successTextLabel}</p>
                </div>}
            </div>
        </Modal>
    );
};

export default ForgotPasswordModal;

import React, { useEffect } from "react";
import useSWR from "swr";
import axios from "axios";
import { Outlet } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import { localizedUrl } from "../../utils/locale";
import { ProductPage } from "../../modals/modals";
import Loader from "../../components/Loader/Loader";

const pageFetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);

export const Products = () => {
    const { locale } = useStateContext();
    const { data: page } = useSWR<ProductPage>(
        localizedUrl('product-page', {
            'populate': '*',
        }, locale),
        pageFetcher
    );

    if (!page) {
        return <Loader />;
    }

    return (
        <Outlet context={{ page }} />
    );
}
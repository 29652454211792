import React, { FC, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import './LocaleSelector.scss';
import { useStateContext } from '../../context/StateContext';
import { DEFAULT_LOCALE, Locale, locales } from '../../assets/constants';
import { getLocaleDisplayName } from '../../utils/locale';

interface Props { };

const LocaleSelector: FC<Props> = ({ }) => {
    const { locale, setLocale } = useStateContext();

    // Preload flags
    useEffect(() => {
        Object.values(locales).forEach(loc => {
            const img = new Image();
            img.src = loc.flagSrc;
        });
    }, []);

    if (!locale || !locales[locale]) {
        return null;
    }

    return (
        <Listbox value={locale} onChange={setLocale}>
            {({ open }) => (
                <>
                    <Listbox.Button
                        className={clsx(
                            'locale-selector__btn-container border-0',
                            'bg-transparent mx-auto',
                            'd-flex align-items-center gap-1')}
                    >
                        <img
                            src={locales[locale].flagSrc}
                            alt={getLocaleDisplayName(locale) || ''}
                            className="locale-selector__btn-flag"
                        />
                    </Listbox.Button>
                    {open && (
                        <div
                            className={clsx(
                                'locale-selector__options-container',
                                'mx-auto',
                                'mb-1 mb-md-0')}
                        >
                            <Listbox.Options
                                className={clsx(
                                    'locale-selector__options',
                                    'd-flex flex-column',
                                    'shadow rounded')}
                            >
                                {Object.entries(locales).map(([key, { flagSrc }]) => (
                                    <Listbox.Option key={key} value={key} as={React.Fragment}>
                                        {({ active, selected }) => (
                                            <li
                                                className={clsx(
                                                    'locale-selector__option',
                                                    active && 'locale-selector__option--active',
                                                    'text-gray-900',
                                                    'd-flex align-items-center gap-1 p-1'
                                                )}
                                            >
                                                <img
                                                    src={flagSrc}
                                                    alt={getLocaleDisplayName(locale) || ''}
                                                    className="locale-selector__option-flag"
                                                />
                                                {getLocaleDisplayName(key)}
                                            </li>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </div>
                    )}
                </>
            )}
        </Listbox>
    );
};

export default LocaleSelector;

import React, { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddressLabels, IDeliveryAdress } from "../../modals/modals";
import './Modals.scss';
import { countries } from "../../assets/constants";
import SelectBox from "../SelectBox/SelectBox";


interface IProps {
    closeModal: Function,
    editAddress: Function,
    address?: IDeliveryAdress | null,
    labels: AddressLabels,
}

export interface OptionalDeliveryAddress {
    country?: string,
    city?: string,
    addressLine1?: string,
    addressLine2?: string,
    // apartmentNumber?: number,
    postalCode?: string,
    note?: string,
    firstName?: string,
    lastName?: string,
    company?: string,
}



export const DeliveryModal: FC<IProps> = ({ closeModal, editAddress, address, labels }) => {
    const { register, handleSubmit, setValue, watch } = useForm<OptionalDeliveryAddress>({
        defaultValues: {
            addressLine1: address?.addressLine1 ?? "",
            addressLine2: address?.addressLine2 ?? "",
            city: address?.city ?? "",
            country: address?.country ?? "",
            postalCode: address?.postalCode ?? "",
            firstName: address?.firstName ?? "",
            lastName: address?.lastName ?? "",
            company: address?.company ?? "",
        }
    });
    const onSubmit: SubmitHandler<OptionalDeliveryAddress> = data => {
        editAddress(data);
        closeModal();
    };

    console.log(labels)
    return (
        <div className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-content">
                <header>
                    <h2 className="modal-title">{address ? labels.changeAddressLabel : labels.newAddressLabel}</h2>
                    <button className="modal-close" onClick={() => closeModal()}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" /></svg>
                    </button>
                </header>
                <form className="py-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-column flex-sm-row align-items-center gap-3 mb-2">
                        <div className="w-100 d-flex flex-column">
                            <label htmlFor="firstName" className="mb-1 fw-semibold">{labels.nameLabel}</label>
                            <input type="text" id="firstName" {...register("firstName")} />
                        </div>
                        <div className="w-100 d-flex flex-column">
                            <label htmlFor="lastName" className="mb-1 fw-semibold">{labels.surnameLabel}</label>
                            <input type="text" id="lastName" {...register("lastName")} />
                        </div>
                    </div>
                    {/* <div className="mb-2 d-flex flex-column">
                        <label htmlFor="firstName" className="mb-1 fw-semibold">Krstné meno</label>
                        <input type="text" id="firstName" {...register("firstName")}/>
                    </div>
                    <div className="mb-2 d-flex flex-column">
                        <label htmlFor="lastName" className="mb-1 fw-semibold">Priezvisko</label>
                        <input type="text" id="lastName" {...register("lastName")}/>
                    </div> */}
                    <div className="mb-2 d-flex flex-column">
                        <label htmlFor="country" className="mb-1 fw-semibold">{labels.countryLabel}</label>
                        <SelectBox
                            placeholder={labels.searchCountryLabel}
                            defaultValue={watch('country')}
                            selectValues={Object.values(countries)}
                            onSelectEvent={(x: string) => setValue('country', x)}
                        />
                    </div>
                    <div className="mb-2 d-flex flex-column">
                        <label htmlFor="addressLine1" className="mb-1 fw-semibold">{labels.streetLabel}</label>
                        <input type="text" id="addressLine1" {...register("addressLine1")} />
                    </div>
                    <div className="mb-2 d-flex flex-column">
                        <input type="text" id="addressLine2" placeholder={labels.streetDetailsLabel} {...register("addressLine2")} />
                    </div>

                    <div className="d-flex flex-column flex-sm-row align-items-center gap-3 mb-2">
                        <div className="w-100 d-flex flex-column">
                            <label htmlFor="city" className="mb-1 fw-semibold">{labels.cityLabel}</label>
                            <input type="text" id="city" {...register("city")} />
                        </div>
                        <div className="w-100 d-flex flex-column">
                            <label htmlFor="postalCode" className="mb-1 fw-semibold">{labels.postalCodeLabel}</label>
                            <input type="text" id="postalCode" {...register("postalCode")} />
                        </div>
                    </div>
                    {/* <div className="mb-2 d-flex flex-column">
                        <label htmlFor="postalCode" className="mb-1 fw-semibold">Poštové číslo</label>
                        <input type="text" id="postalCode" {...register("postalCode")}/>
                    </div>
                    <div className="mb-2 d-flex flex-column">
                        <label htmlFor="city" className="mb-1 fw-semibold">Mesto</label>
                        <input type="text" id="city" {...register("city")}/>
                    </div> */}
                    <div className="mb-2 d-flex flex-column">
                        <label htmlFor="company" className="mb-1 fw-semibold">{labels.companyLabel}</label>
                        <input type="text" id="company" {...register("company")} />
                    </div>

                    <button type="submit" className="btn --primary">{address ? labels.buttonChangeLabel : labels.buttonAddLabel}</button>
                </form>
            </div>
        </div>
    );
}

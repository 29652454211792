import React, { useContext, useEffect } from "react";
import { createContext, FC, useState } from "react";
import toast from "react-hot-toast";
import { ICartProduct, ProductErrors } from "../modals/modals";
import { getLocaleFromIP } from "../utils/locale";
import { DEFAULT_LOCALE, locales } from "../assets/constants";

interface AppContextInterface {
    showCart: boolean;
    menuVisibility: boolean;
    loginModal: boolean;
    consentModal: boolean;
    registerModal: boolean;
    forgotPasswordModal: boolean;
    cartItems: ICartProduct[];
    totalPrice: number;
    totalQuantities: number;
    quantity: number;
    selectedCategory: string;
    locale: string | null;
    setQuantity: (a: number) => void;
    incQuantity: () => void;
    decQuantity: () => void;
    setShowCart: (show: boolean) => void;
    setMenuVisibility: (show: boolean) => void;
    setLoginModal: (show: boolean) => void;
    setForgotPasswordModal: (show: boolean) => void;
    setConsentModal: (show: boolean) => void;
    setRegisterModal: (show: boolean) => void;
    addToCart: (product: ICartProduct, quantity: number, texts: ProductErrors) => void;
    changeItemQuantity: (id: number, val: string) => void;
    removeFromCart: (id: number) => void;
    setSelectedCategory: (x: string) => void;
    setCartItems: (products: ICartProduct[]) => void;
    setTotalPrice: (p: number) => void;
    setTotalQuantities: (q: number) => void;
    setLocale: (l: string) => void;
}

interface Props {
    children: React.ReactNode;
}

export const Context = createContext({} as AppContextInterface);

export const StateContext: FC<Props> = ({ children }) => {
    const localStoragePrice = localStorage.getItem('totalPrice');
    const localStorageQty = localStorage.getItem('totalQty');
    const localStorageCartItems = localStorage.getItem('cartItems');
    const localStorageConsent = localStorage.getItem('consent');
    const localStorageLocale = localStorage.getItem('locale');

    const [showCart, setShowCart] = useState(false);
    const [menuVisibility, setMenuVisibility] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [consentModal, setConsentModal] = useState(localStorageConsent ? localStorageConsent !== 'false' : true);

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [cartItems, setCartItems] = useState<ICartProduct[]>(localStorageCartItems ? JSON.parse(localStorageCartItems) : []);
    const [totalPrice, setTotalPrice] = useState<number>(localStoragePrice ? +localStoragePrice : 0);
    const [totalQuantities, setTotalQuantities] = useState<number>(localStorageQty ? +localStorageQty : 0);
    const [quantity, setQuantity] = useState<number>(1);

    const [locale, setLocaleState] = useState<string | null>(localStorageLocale || null);

    const setLocale = (l: string) => {
        if (l in locales) {
            setLocaleState(l);
            localStorage.setItem('locale', l);

            if (l !== locale) {
                setCartItems([]);
                localStorage.setItem('cartItems', JSON.stringify([]));

                setTotalPrice(0);
                localStorage.setItem('totalPrice', '0');

                setTotalQuantities(0);
                localStorage.setItem('totalQty', '0');
            }
        }
    }

    useEffect(() => {
        const fetchLocale = async () => {
            const loc = await getLocaleFromIP();
            setLocale(loc || DEFAULT_LOCALE);
        };

        if (!locale || !locales[locale]) {
            fetchLocale();
        }
    }, [locale]);

    // useEffect(() => {
    //     console.log(locale);
    //     if (!locale || locales[locale]) {
    //         setLocale(DEFAULT_LOCALE);
    //     }
    // }, [locale]);

    useEffect(() => {
        localStorage.setItem('totalPrice', totalPrice.toString());
        localStorage.setItem('totalQty', totalQuantities.toString());
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems, totalPrice, totalQuantities])

    const incQuantity = () => {
        setQuantity(quantity + 1);
    }

    const decQuantity = () => {
        if (quantity - 1 < 1) {
            setQuantity(1)
        } else {
            setQuantity(quantity - 1);
        }
    }

    const changeItemQuantity = (id: number, val: string) => {

        const updatedCartItems = cartItems.map(
            (item) => {
                if (item.id === id) {
                    const newQty = val === 'dec' ? item.quantity - 1 : item.quantity + 1;
                    if (newQty === 0) {
                        return item;
                    }
                    setTotalPrice(totalPrice - item.quantity * item.price + item.price * newQty);
                    setTotalQuantities(totalQuantities - item.quantity + newQty);
                    return {
                        ...item,
                        quantity: newQty
                    }
                }
                return item;
            }
        );

        setCartItems(updatedCartItems);
    }

    const removeFromCart = (id: number) => {
        const foundItem = cartItems.find(x => x.id === id);

        if (!foundItem) {
            return;
        }

        setTotalQuantities(totalQuantities - foundItem?.quantity);
        setTotalPrice(totalPrice - foundItem.quantity * foundItem.price);
        setCartItems(cartItems.filter(x => x.id !== id));
    }

    const addToCart = (product: ICartProduct, quantity: number, texts: ProductErrors) => {

        if (product.stockQuantity === 0) {
            toast.error(texts.emptyStock);
            return;
        }

        const isProductInCart = cartItems?.find((item) => product.id === item.id);


        setTotalPrice(totalPrice + product.price * quantity);
        setTotalQuantities(totalQuantities + quantity);

        if (isProductInCart) {
            const updatedCartItems = cartItems?.map((item) => {
                if (item.id === product.id) {
                    return {
                        ...item,
                        quantity: item.quantity + quantity
                    }
                }
                return item;
            });
            setCartItems(updatedCartItems);
        } else {
            product.quantity = quantity;
            setCartItems([...cartItems, product])
        }

        toast.success(texts.addedToCart);
    }

    return (
        <Context.Provider
            value={{
                showCart,
                loginModal,
                registerModal,
                consentModal,
                forgotPasswordModal,
                menuVisibility,
                cartItems,
                totalPrice,
                totalQuantities,
                quantity,
                selectedCategory,
                locale,
                decQuantity,
                incQuantity,
                setQuantity,
                addToCart,
                setShowCart,
                setLoginModal,
                setConsentModal,
                setRegisterModal,
                setMenuVisibility,
                setCartItems,
                changeItemQuantity,
                removeFromCart,
                setSelectedCategory,
                setTotalPrice,
                setTotalQuantities,
                setForgotPasswordModal,
                // setResetPasswordModal,
                setLocale,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export const useStateContext = () => useContext(Context);

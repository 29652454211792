import React from 'react';
import Paperwork from "./Paperwork";

const PaymentDeliveryConditions = () => {
    return (
        <Paperwork url='payment-and-delivery-condition'/>
    );
};

export default PaymentDeliveryConditions;

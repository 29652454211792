import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './Modals.scss';
import axios from "axios";
import { IAction, useDispatchCurrentUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { UserAction } from "../../enums/enums";
import { RegisterPage, User } from "../../modals/modals";
import toast from "react-hot-toast";
import { useStateContext } from "../../context/StateContext";
import Modal from "./Modal";
import Spinner from "../Spinner/Spinner";
import { registerUser } from "../../utils/user";
import { localizedUrl } from "../../utils/locale";
import useSWR from "swr";
import Loader from "../Loader/Loader";

export interface IRegisterInputs {
    username: string,
    firstName: string
    lastName: string,
    email: string,
    password: string,
    phone: string,
}

const registerSchema = yup.object({
    username: yup.string().min(3).required(),
    firstName: yup.string().min(2).required(),
    lastName: yup.string().min(2).required(),
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
    phone: yup.string().trim()
        .transform((value: string | undefined | null) => {
            if (!value) {
                return null;
            }
            return value.split(" ").join("");
        })
        .matches(
            /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
            "Telefónne číslo má nesprávny formát").optional().nullable()
}).required();



const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);

export const RegisterModal = () => {
    const { setLoginModal, setRegisterModal } = useStateContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { dispatch } = useDispatchCurrentUser();

    const { register, handleSubmit, formState: { errors } } = useForm<IRegisterInputs>({
        resolver: yupResolver(registerSchema)
    });

    const { locale } = useStateContext();
    const url = localizedUrl('register-page', { 'populate': '*' }, locale);

    const { data } = useSWR<RegisterPage>(url, fetcher);




    const onSubmit = async (RegData: IRegisterInputs) => {
        setLoading(true);
        registerUser(
            RegData,
            (response) => {
                dispatch({ type: UserAction.Login, user: response.data.user as User })
                setRegisterModal(false);
                setLoading(false);
                navigate('/moj-ucet');
            },
            () => {
                setLoading(false);
                // toast.error('Nepodarilo sa vytvoriť účet.')
                toast.error(data?.toastErrorLabel || 'Error')
            }
        )
    }
    // const onSubmit = async (data: IRegisterInputs) => {
    //     setLoading(true);
    //     try {
    //         const response = await axios.post('/auth/local/register', data)

    //         if (response.status === 200) {
    //             dispatch({ type: UserAction.Login, user: response.data.user as User })
    //             setRegisterModal(false);
    //             navigate('/moj-ucet');
    //         } else {
    //             toast.error('Nepodarilo sa vytvoriť účet.')
    //         }
    //         setLoading(false);
    //     } catch (e) {
    //         setLoading(false);
    //         toast.error('Nepodarilo sa vytvoriť účet.')
    //     }
    // };

    if (!data) {
        return <Loader />
    }

    return (
        <Modal title={data.registerTitleLabel} close={setRegisterModal}>
            <form className="py-4 register-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-2 d-flex flex-column ">
                    {errors.username?.type === 'required' && <p className="alert" role="alert">{data.usernameErrorRequiredLabel}</p>}
                    {errors.username?.type === 'min' && <p className="alert" role="alert">{data.usernameErrorLengthLabel}</p>}
                    <label htmlFor="username" className="mb-1 fw-semibold">{data.usernameLabel}</label>
                    <input type="text" id="username" {...register("username")} />
                </div>
                <div className="mb-2 d-flex flex-column">
                    {errors.firstName?.type === 'required' && <p className="alert" role="alert">{data.nameErrorRequiredLabel}</p>}
                    {errors.firstName?.type === 'min' && <p className="alert" role="alert">{data.nameErrorLengthLabel}</p>}
                    <label htmlFor="name" className="mb-1 fw-semibold">{data.nameLabel}</label>
                    <input type="text" id="name" {...register("firstName")} />
                </div>
                <div className="mb-2 d-flex flex-column">
                    {errors.lastName?.type === 'required' && <p className="alert" role="alert">{data.surnameErrorRequiredLabel}</p>}
                    {errors.lastName?.type === 'min' && <p className="alert" role="alert">{data.surnameErrorLengthLabel}</p>}
                    <label htmlFor="surname" className="mb-1 fw-semibold">{data.surnameLabel}</label>
                    <input type="text" id="surname" {...register("lastName")} />
                </div>
                <div className="mb-2 d-flex flex-column">
                    {errors.email?.type === 'required' && <p className="alert" role="alert">{data.emailErrorRequiredLabel}</p>}
                    {errors.email?.type === 'email' && <p className="alert" role="alert">{data.emailErrorWrongFormatLabel}</p>}
                    <label htmlFor="email" className="mb-1 fw-semibold">{data.emailLabel}</label>
                    <input type="email" id="email" {...register("email")} />
                </div>

                <div className="mb-2 d-flex flex-column">
                    {errors.phone && <p className='text-danger fw-bold mb-1'>{errors.phone.message}</p>}
                    {/* {errors.phone && <p className='text-danger fw-bold mb-1'>Vyplň dané políčko.</p>} */}

                    <label htmlFor="phone" className="mb-1 fw-semibold">{data.phoneNumberLabel}</label>
                    <input type="text" {...register("phone")} id="phone" placeholder="+421 940 123 456" />
                </div>

                <div className="mb-3 d-flex flex-column">
                    {errors.password?.type === 'required' && <p className="alert" role="alert">{data.passwordErrorRequiredLabel}</p>}
                    {errors.password?.type === 'min' && <p className="alert" role="alert">{data.passwordErrorLengthLabel}</p>}
                    <label htmlFor="password" className="mb-1 fw-semibold">{data.passwordLabel}</label>
                    <input type="password" id="password" {...register("password")} />
                </div>

                <div className="d-flex flex-column flex-sm-row gap-3 align-items-center justify-content-center justify-content-sm-between">
                    <button type="submit" className="btn --primary">
                        {!loading && data.registerButtonLabel}
                        {loading && <Spinner />}
                    </button>
                    <button type="button" className="btn --tertiary" onClick={() => {
                        setRegisterModal(false);
                        setLoginModal(true);
                    }}>
                        <div>{data.loginButtonLabel}</div>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </form>
        </Modal>
    );
}

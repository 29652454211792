import { faChevronLeft, faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from '../../context/StateContext';
import { CartPage } from '../../modals/modals';
import './Cart.scss';
import useSWR from 'swr';
import axios from 'axios';
import { Currency, getCurrencyFromLocale, localizedUrl } from '../../utils/locale';
import Loader from '../Loader/Loader';
import { DEFAULT_LOCALE } from '../../assets/constants';

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);


const Cart = () => {
  const { totalQuantities, showCart, setShowCart, cartItems, totalPrice, changeItemQuantity, removeFromCart } = useStateContext();

  const { locale } = useStateContext();
  const url = localizedUrl('cart-page', { 'populate': '*' }, locale);
  const { data } = useSWR<CartPage>(url, fetcher);

  const [currency, setCurrency] = useState<Currency>(getCurrencyFromLocale(DEFAULT_LOCALE));
  useEffect(() => {
    if (locale) {
      setCurrency(getCurrencyFromLocale(locale));
    }
  }, [locale]);

  const { host } = window.location;

  if (!setShowCart || !cartItems || !changeItemQuantity || !removeFromCart) {
    return <div>Not </div>
  }

  return (
      <div className={"cart-container" + (showCart ? ' --expanded': '')}>

      <button
        type="button"
        className="cart-heading mb-5"
        onClick={() => setShowCart(false)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="heading">{data?.titleCartLabel} ({totalQuantities} {data?.quantityLabel})</span>
        </button>


        {cartItems.length < 1 && (
          <div className="empty-cart">
            <h3 className='mb-3'>{data?.emptyCartLabel} :(</h3>
            <Link to="/produkty">
              <button
                type="button"
                onClick={() => setShowCart(false)}
                className="btn --primary d-inline-flex"
              >
                {data?.continueShoppingButtonLabel}
              </button>
            </Link>
          </div>
        )}

        <div className="product-container mb-3">
          {cartItems.length >= 1 && cartItems.map((item) => (
            <div className="cart-product" key={item.id}>
              <img src={'https://api.' + host + item.mainImage.data.attributes.url} className="cart-product-image" />
              <div className="item-desc">
                <div className="item-headings">
                  <h5>{item.name}</h5>
                  <div className="position-relative">
                    <span className="product-detail-price-sign">{currency.sign}</span>
                    <h4 className='product-detail-price'>{item.price}</h4>
                  </div>
                </div>
                <div className="item-controls">
                  <div className="quantity-desc">
                    <span className="minus" onClick={() => changeItemQuantity(item.id, 'dec')}>
                      <FontAwesomeIcon icon={faMinus}/>
                    </span>
                    <span className="num">{item.quantity}</span>
                    <span className="plus" onClick={() => changeItemQuantity(item.id, 'inc')}>
                      <FontAwesomeIcon icon={faPlus}/>
                    </span>
                  </div>
                  <button
                    type="button"
                    className="remove-item"
                    onClick={() => removeFromCart(item.id)}
                  >
                    <FontAwesomeIcon icon={faTrash}/>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {cartItems.length >= 1 && (
          <div className="cart-bottom">
            <div className="total mb-3">
              <h3>{data?.totalLabel}:</h3>
            <h3>{totalPrice} {currency.sign}</h3>
            </div>

            <Link to='/kontrola-objednavky' type='button' className='btn --primary'>
              {data?.buttonLabel}
            </Link>
          </div>
        )}
      </div>
    // </div>
  )
}

export default Cart

import React, { useEffect, useState } from 'react';
import './SelectBox.scss';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SelectItem = { label: string, value: string };

type ISelectBoxProps = {
    placeholder: string;
    selectValues?: string[];
    onSelectEvent: (label: string, index: number, value: string) => void;
    defaultValue?: string,
    selectItems?: SelectItem[],
}

const SelectBox: React.FC<ISelectBoxProps> = ({
    selectValues,
    onSelectEvent,
    placeholder,
    defaultValue,
    selectItems
}: ISelectBoxProps) => {
    if (!!selectValues == !!selectItems) {
        throw new Error(
            "One of `selectValues` and `selectItems` must be specified");
    }
    const items: SelectItem[] = selectItems
        ? selectItems
        : (selectValues || []).map(
            (value: string) => ({ label: value, value }));

    const [searchingValue, setSearchingValue] = useState('');
    const [selectedValue, setSelectedValue] = useState<SelectItem>(
        defaultValue && items.find(x => x.value == defaultValue) || { label: '', value: '' });
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className='select-box'>
            <div className='select-box__option --selected' onClick={() => setShowMenu(prev => !prev)}>
                {selectedValue.value !== '' ? selectedValue.label : placeholder}
                <FontAwesomeIcon className='select-box__icon' icon={faChevronDown} />
            </div>

            {showMenu && <div className='select-box__controls'>
                <div className="select-box__search">
                    <input type="text" placeholder={placeholder} onChange={(e) => setSearchingValue(e.target.value)} autoFocus={true} />
                </div>

                <div className="select-box__options">
                    {
                        items
                            .filter(x =>
                                x.label
                                    .toLowerCase()
                                    .startsWith(searchingValue.toLowerCase()))
                            .map((x, index) => (
                                <div
                                    key={x.value}
                                    onClick={() => {
                                        onSelectEvent(x.label, index, x.value)
                                        setSearchingValue('');
                                        setSelectedValue(x);
                                        setShowMenu(false);
                                    }}
                                    className="select-box__option"
                                >
                                    <input type="radio" name={x.value} id={x.value} />
                                    <label htmlFor={x.value}>{x.label}</label>
                                </div>
                            ))
                    }
                    {/* {selectValues.filter(c => c.toLowerCase().startsWith(searchingValue.toLowerCase())).map((c, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    onSelectEvent(c, index)
                                    setSearchingValue('');
                                    setSelectedValue(c);
                                    setShowMenu(false);
                                }}
                                className="select-box__option">
                                <input type="radio" id={c} />
                                <label htmlFor={c}>
                                    {c}
                                </label>
                            </div>
                        )
                    })} */}
                </div>
            </div>}
        </div>
    );
};

export default SelectBox;

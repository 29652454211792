import {
    faAddressCard,
    faClockRotateLeft,
    faGift,
    faKey,
    faPowerOff,
    faUserXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import { useCurrentUser, useDispatchCurrentUser } from "../../context/UserContext";
import { UserAction } from "../../enums/enums";
import { AboutPage, ProfilePage } from "../../modals/modals";
import { localizedUrl } from "../../utils/locale";
import './Profile.scss';
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);


export const Profile = () => {

    const { locale } = useStateContext();
    const url = localizedUrl('profile-page', {'populate': '*'}, locale);

    const {data} = useSWR<ProfilePage>(url, fetcher);
    
    const { dispatch } = useDispatchCurrentUser();
    const user = useCurrentUser();
    

    const handleLogout = () => {
        axios.post('/auth/logout', { withCredentials: true });
        dispatch({ type: UserAction.Logout });
    }

    if (!user.state.isAuthenticated) {
        return <Navigate to={'/'} />;
    }

    if (!data) {
        return <Loader />;
    }

    return (
        <div>
            <div className="banner mb-3">
                <h3 className="text-center">{data?.title}</h3>
            </div>
            <div className="container-fluid">
                <div className="row d-flex gap-3 justify-content-center">
                    <aside className="profile-aside col-12 col-md-3">
                        <ul className="profile-nav">
                            <li>
                                <NavLink to={''} end>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                    {data?.detailsLabel}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'historia-objednavok'}>
                                    <FontAwesomeIcon icon={faClockRotateLeft} />
                                    {data?.orderHistoryLabel}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={''} className="disabled">
                                    <FontAwesomeIcon icon={faGift} />
                                    {data?.programLabel}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'zmena-hesla'}>
                                    <FontAwesomeIcon icon={faKey} />
                                    {data?.passwordLabel}
                                </NavLink>
                            </li>
                            <li>
                                <button className="btn" type="button" onClick={() => handleLogout()}>
                                    <FontAwesomeIcon icon={faPowerOff} />
                                    <span>{data?.logoutLabel}</span>
                                </button>
                            </li>
                        </ul>
                    </aside>

                    <div className="col-12 col-md-7 mb-5 flex-fill">
                        <Outlet context={{data}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

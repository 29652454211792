import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useSWR, { Fetcher } from 'swr';
import { useStateContext } from "../../context/StateContext";
import { CheckoutSuccessPage } from '../../modals/modals';
import { localizedUrl } from '../../utils/locale';
import Loader from '../../components/Loader/Loader';

const CheckoutSuccess = () => {
    const {
        setCartItems,
        setTotalQuantities,
        setTotalPrice,
        locale
    } = useStateContext();

    const fetcher: Fetcher<CheckoutSuccessPage> =
        (url: string) => axios
            .get(url)
            .then(res => res.data.data.attributes);
    const { data: page, error: pageError } = useSWR<CheckoutSuccessPage>(
        localizedUrl('checkout-success-page', {}, locale),
        fetcher
    );

    useEffect(() => {
        setCartItems([]);
        setTotalQuantities(0);
        setTotalPrice(0);
    });

    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);

    useEffect(() => {
        const updateHeights = () => {
            const header = document.querySelector('.header');
            const footer = document.querySelector('.footer');
            setHeaderHeight(header?.clientHeight || 0);
            setFooterHeight(footer?.clientHeight || 0);
        };

        updateHeights();

        window.addEventListener('resize', updateHeights);

        return () => window.removeEventListener('resize', updateHeights);
    }, []);


    if (!page && !pageError) {
        return <Loader />;
    }

    return (
        <div className='container-fluid' style={{ minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)` }}>
            <div className='row align-items-center justify-content-center text-center'>
                <h2 className='mb-2'>{page?.title || 'Thank you for the order, it\'s on the way!'}</h2>
                <p>{page?.body || 'In case you have any questions, call +421 944 408 628.'}</p>
            </div>
        </div>
    );
};

export default CheckoutSuccess;

import { DEFAULT_LOCALE, Locale, locales } from "../assets/constants";
import CountryLanguage from 'country-language';

export async function getCountryFromIP() {
    try {
        const response = await fetch(`https://ipinfo.io/json`, { mode: "cors" });  // ?token=d97b6d33dbddd3
        const data: { country: string } = await response.json();

        return data.country;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export function getCountryFromLocale(locale: string) {
    const [lang, country] = locale.split('-');
    return (country || lang).toLowerCase();
}

export function getLanguageFromLocale(locale: string) {
    const [lang, country] = locale.split('-');
    return (lang || country).toLowerCase();
}

export interface Currency {
    code: string;
    sign: string;
}

export function getCurrencyFromLocale(locale: string) {
    return {
        code: locales[locale]?.currencyCode,
        sign: locales[locale]?.currency,
    };
}

export function getDefaultLanguageCodeFromCountry(country: string) {
    return CountryLanguage.getCountryLanguages(country)[0]?.iso639_1;
}

export function getDefaultLocaleFromCountry(country: string) {
    country = country.toLowerCase();
    const countryLocales = Object
        .entries(locales)
        .filter(([, locale]: [string, Locale]) =>
            locale.countryCode.toLowerCase() === country);
    const defaultLocale = countryLocales
        .find(([locale,]: [string, Locale]) => locale.includes('-'));

    return defaultLocale ? defaultLocale[0] : (countryLocales[0]?.[0] || DEFAULT_LOCALE);
}

export function getLanguageNameFromCode(code: string) {
    code = code.toLowerCase();
    return Object
        .values(locales)
        .find((locale) =>
            locale.languageCode.toLowerCase() === code)?.languageName || code;
}

export function getCountryNameFromCode(code: string) {
    code = code.toLowerCase();
    return Object
        .values(locales)
        .find((locale) =>
            locale.countryCode.toLowerCase() === code)?.countryName || code;
}

export async function getLocaleFromIP() {
    const country = await getCountryFromIP();
    let locale: string | null = '';
    if (country) {
        locale = getDefaultLocaleFromCountry(country);
    }
    return locale || DEFAULT_LOCALE;
}

export function getLocaleDisplayName(locale: string) {
    if (!locales[locale]) {
        return null;
    }
    let res = locales[locale].languageName;
    if (locale.includes('-')) {
        res += ` (${locales[locale].countryCode.toUpperCase()})`;
    }
    return res;
}

export function localizedUrl(
    baseUrl: string,
    searchParams: string[][] | Record<string, string> | string | URLSearchParams,
    locale?: string | null
): string {
    const sp = new URLSearchParams(searchParams);
    if (locale) {
        sp.set('locale', locale);
    }

    let url = baseUrl;
    if (!url.includes('?')) {
        url += '?';
    } else if (!url.endsWith('?') && !url.endsWith('&')) {
        url += '&';
    }
    return url + sp.toString();
}

import React from "react";
import { Outlet } from "react-router-dom";

export const Blog = () => {
    return (
        <div className="container-fluid">
            <Outlet/>
        </div>
    );
}

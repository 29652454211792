import React, {FC, useState, useEffect} from 'react';
import './Modals.scss';

type Props = {
    title: string,
    close: (x: boolean) => void,
    children?: React.ReactNode
}
const CashBackModal: FC<Props> = ({title, close, children}) => {
    const [yOffset, setYOffset] = useState(0);
    const [rightModal, setRightModal] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    const handleScroll = () => {
        if (window.pageYOffset > 750) {
            setRightModal(true)
        }
    }

    return (
        <div className="modal">
            <div className="modal-overlay" onClick={() => close(false)}></div>
            <div className={rightModal ? "modal-content-left" : "modal-content"}>
                <header>
                    <h2 className="modal-title">{title}</h2>
                    <button className="modal-close" onClick={() => close(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                        </svg>
                    </button>
                </header>
                {children}
            </div>
        </div>
    );
};
export default CashBackModal;

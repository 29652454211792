import React from "react";
import Banner, { BannerProps } from "./Banner";
import bannerSkSmall from '../../assets/images/banner-sk-600.webp';
import bannerSkMedium from '../../assets/images/banner-sk-1200.webp';
import bannerSkLarge from '../../assets/images/banner-sk-2400.webp';
import bannerDkSmall from '../../assets/images/banner-dk-600.webp';
import bannerDkMedium from '../../assets/images/banner-dk-1200.webp';
import bannerDkLarge from '../../assets/images/banner-dk-2400.webp';
import bannerDkXLarge from '../../assets/images/banner-dk-4800.webp';

const bannerSkProps: BannerProps = {
    buttonText: "ZISTI VIAC",
    redirectUrl: "https://viabotanica.eu/?locale=sk",
    srcset: {
        portrait: [
            {
                url: bannerSkSmall,
                width: 600,
            },
            {
                url: bannerSkMedium,
                width: 1200,
            },
            {
                url: bannerSkLarge,
                width: 2400,
            },
        ],
        landscape: [
            {
                url: bannerSkSmall,
                width: 600,
            },
            {
                url: bannerSkMedium,
                width: 1200,
            },
            {
                url: bannerSkLarge,
                width: 2400,
            },
        ],
    },
};

const bannerDkProps: BannerProps = {
    buttonText: "FIND UD AF MERE",
    redirectUrl: "https://viabotanica.eu/?locale=da",
    srcset: {
        portrait: [
            {
                url: bannerDkSmall,
                width: 600,
            },
            {
                url: bannerDkMedium,
                width: 1200,
            },
            {
                url: bannerDkLarge,
                width: 2400,
            },
            {
                url: bannerDkXLarge,
                width: 4800,
            },
        ],
        landscape: [
            {
                url: bannerDkSmall,
                width: 600,
            },
            {
                url: bannerDkMedium,
                width: 1200,
            },
            {
                url: bannerDkLarge,
                width: 2400,
            },
            {
                url: bannerDkXLarge,
                width: 4800,
            },
        ],
    },
};

const domainProps: Record<string, BannerProps> = {
    "viabotanica.sk": bannerSkProps,
    "viabotanica.dk": bannerDkProps,
};

const DomainBanner: React.FC = () => {
    const props = window.location.hostname in domainProps
                  ? domainProps[window.location.hostname]
                  : bannerSkProps;
    return (
        <Banner {...props} />
    );
};

export default DomainBanner;

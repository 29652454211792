import React, { useState, FC } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatchCurrentUser } from "../../context/UserContext";
import { User } from "../../modals/modals";
import toast from "react-hot-toast";
import { UserAction } from "../../enums/enums";
import Modal from "./Modal";
import Spinner from "../Spinner/Spinner";

interface IResetPasswordInputs {
    code: string,
    password: string,
    passwordConfirmation: string,
}

const resetPasswordSchema = yup.object({
    code: yup
        .string(),
    // .min(8)
    //.required('Staré heslo je povinné'),
    password: yup
        .string()
        .min(8, 'Minimálna dĺžka hesla je 8 znakov')
        .required('Nové heslo je povinné'),
    passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password")], "Heslá sa nezhodujú")
        .required('Nové heslo je povinné'),
}).required();

type Props = {
    code: string,
    close: (x: boolean) => void,
}

export const ResetPasswordModal: FC<Props> = ({ code, close }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { dispatch } = useDispatchCurrentUser();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<IResetPasswordInputs>({
        resolver: yupResolver(resetPasswordSchema)
    });


    const onSubmit = async (data: IResetPasswordInputs) => {
        setLoading(true);
        try {
            const response = await axios.post('auth/reset-password', data);

            if (response.status !== 200) {
                setLoading(false);
                toast.error('Nepodarilo sa zmeniť heslo.')
                return;
            }

            dispatch({ type: UserAction.Updated, user: response.data.user as User })
            setLoading(false);
            navigate('');
            toast.success('Heslo bolo úspešne zmenené.');

        } catch (e) {
            const error = e as AxiosError;
            setLoading(false);
            toast.error('Nepodarilo sa zmeniť heslo.');
            if (error.response?.status === 500) {
                toast.error('Staré heslo musí byť odlišné od nového.');
            }
        }
    }

    return (
        <Modal title={"Zmena zabudnutého hesla"} close={
            () => navigate("/")
        }>
            <form onSubmit={handleSubmit(onSubmit)}>

                <p className='text-danger'>{errors.code?.message}</p>
                <div className='mb-1'>
                    <input type='hidden' value={code} {...register('code')} />
                    {/* {register('code', { value: code })} */}
                </div>
                <p className='text-danger'>{errors.password?.message}</p>
                <div className='mb-1'>
                    <label>Nové heslo</label>
                    <input type='password'  {...register('password')} />
                </div>
                <p className='text-danger'>{errors.passwordConfirmation?.message}</p>
                <div className='mb-1'>
                    <label>Zopakovať nové heslo</label>
                    <input type='password' {...register('passwordConfirmation')} />
                </div>

                <button type='submit' className='btn --primary'>
                    {!loading && 'Zmeniť heslo'}
                    {loading && <Spinner />}
                </button>

            </form>
        </Modal >
    );
};
import React, { StrictMode } from 'react';
import './assets/scss/app.scss';
import { App } from './App';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { StateContext } from './context/StateContext';
import { Toaster } from 'react-hot-toast';
import { CurrentUserProvider } from './context/UserContext';
import DomainBanner from './components/Banner/DomainBanner';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    (window.location.hostname === "viabotanica.eu") ? (
        <StrictMode>
            <CurrentUserProvider>
                <StateContext>
                    <Toaster />
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </StateContext>
            </CurrentUserProvider>
        </StrictMode>
    ) : (
        <DomainBanner/>
    )
);

import { faPenToSquare, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { DeliveryModal } from "../../../components/Modals/DeliveryModal";
import { IDeliveryAdress, ProfilePage } from "../../../modals/modals";
import * as yup from "yup";
import './AccountDetails.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  useCurrentUser,
  useDispatchCurrentUser
} from "../../../context/UserContext";
import toast from "react-hot-toast";
import axios from "axios";
import { UserAction } from "../../../enums/enums";
import Spinner from "../../../components/Spinner/Spinner";
import { object } from "yup";
import { useOutletContext } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

const formatAddress = (address: IDeliveryAdress) => {
  return `${address.addressLine1}, ${address.city}`;
}

interface IAccountInputs {
  username: string,
  email: string,
  shippingAddress?: [IDeliveryAdress],
  billingAddress?: IDeliveryAdress | null,
  newsletter?: boolean,
  phone?: string,
}

const accountSchema = yup.object({
  username: yup.string().required(),
  email: yup.string().email().required(),
  shippingAddress: yup.array().of(object().required()).optional(),
  billingAddress: yup.object().optional().nullable(),
  newsletter: yup.boolean().optional(),
  phone: yup.string().trim()
    .transform((value: string | undefined | null) => {
      if (!value) {
        return null;
      }
      return value.split(" ").join("");
    })
    .matches(
      /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
      "Telefónne číslo má nesprávny formát").optional().nullable()
}).required();

export const AccountDetails = () => {
  const {data: {accountDetails, address: addressLabels}} = useOutletContext<{data: ProfilePage}>();

  const user = useCurrentUser();
  const [addressModalVisibility, setAddressModalVisibility] = useState(false);
  const [addressIndex, setAddressIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useDispatchCurrentUser();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IAccountInputs>({
    resolver: yupResolver(accountSchema),
    defaultValues: {
      username: user.state.user?.username,
      email: user.state.user?.email,
      shippingAddress: user.state.user?.shippingAddress,
      billingAddress: user.state.user?.billingAddress,
      newsletter: user.state.user?.newsletter,
      phone: user.state.user?.phone,
    }
  });

  useEffect(() => {
    setValue('username', user.state.user?.username ?? '');
    setValue('email', user.state.user?.email ?? '')
    setValue('billingAddress', user.state.user?.billingAddress);
    setValue('shippingAddress', user.state.user?.shippingAddress);
    setValue('newsletter', user.state.user?.newsletter);
    setValue('phone', user.state.user?.phone);
  }, [user.state.user]);

  const onSubmit = async (data: IAccountInputs) => {
    setLoading(true);
    try {
      const updateResponse = await axios.put('/users/me', { data });
      if (updateResponse.status !== 200) {
        setLoading(false);
        toast.error(accountDetails.toastError1Label);
        return;
      }
      console.log(updateResponse);

    } catch (e) {
      setLoading(false);
      toast.error('Nepodarilo sa zmeniť účet.');
      return;
    }
    toast.success(accountDetails.toastSuccessLabel);


    try {
      const userResponse = await axios.get('/users/me?populate=*');
      if (userResponse.status !== 200) {
        setLoading(false);
        toast.error(accountDetails.toastError1Label);
        return;
      }

      dispatch({ type: UserAction.Updated, user: userResponse.data });
      setLoading(false);
      // toast.success('Účet bol úspešne zmenený.');
    } catch (e) {
      setLoading(false);
      toast.error(accountDetails.toastError2Label);
    }
  };

  const editAddress = (address: IDeliveryAdress) => {
    console.log(address)
    if (addressIndex === -1) {
      setValue('billingAddress', address);
    } else {
      const shippingAddress = getValues('shippingAddress');
      if (!shippingAddress) {
        return;
      }
      shippingAddress[addressIndex] = address;
      setValue('shippingAddress', shippingAddress);
    }
  }

  const openAddressModal = () => setAddressModalVisibility(true);
  const closeAddressModal = () => setAddressModalVisibility(false);

  if (!accountDetails) {
    return <Loader />
  }
  return (
    <div>
      {addressModalVisibility
        && <DeliveryModal
          address={(addressIndex === -1) ? getValues('billingAddress') : getValues('shippingAddress')![addressIndex]}
          editAddress={editAddress}
          closeModal={closeAddressModal}
          labels={addressLabels}

        />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gap-3 justify-content-between mb-2">
          <div className="col-12 col-sm-5">
            <label htmlFor="username" className="mb-1 fw-semibold">{accountDetails.nameLabel}</label>
            <input {...register('username')} name="username" type="text"
              id="name" aria-describedby="emailHelp" />
          </div>
          <div className="col-12 col-sm-5">
            <label htmlFor="email" className="mb-1 fw-semibold">{accountDetails.emailLabel}</label>
            <input {...register('email')} type="email" id="email"
              aria-describedby="emailHelp" />
          </div>

          <div className="row gap-3 justify-content-between mb-2">
          {errors.phone && (
                <p className='text-danger'>
                    {accountDetails.phoneNumberSchemaLabel}
                </p>
            )}
            <div className="col-12 col-sm-5">
              <label htmlFor="phone" className="mb-1 fw-semibold">{accountDetails.phoneNumberLabel}</label>
              <input {...register('phone')} name="phone" type="text" id="phone" />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12 d-flex gap-1 align-items-center">
            <label htmlFor="newsletter" className="fw-semibold">{accountDetails.newsletterLabel}</label>
            <input {...register('newsletter')} className="newsletter-agree" type="checkbox" id="newsletter" />
          </div>
        </div>

        <div className="adress">
          {/*<h4>Fakturačná adresa</h4>*/}
          <div>
            <span className="fw-semibold">{accountDetails.billingAddressLabel}</span>
            {watch('billingAddress') && <span className="ms-2">{formatAddress(getValues('billingAddress')!)}</span>}
          </div>
          <div>
            {watch('billingAddress') &&
              <button type="button" onClick={() => {
                setValue('billingAddress', null);
              }}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            }
            <button type="button" onClick={() => {
              setAddressIndex(-1);
              openAddressModal();
            }}>
              <FontAwesomeIcon
                icon={watch('billingAddress') ? faPenToSquare : faPlus} />
            </button>
          </div>
        </div>

        {
          (watch('shippingAddress') ?? []).map((address, index) => (
            <div className="adress" key={index}>
              {/*<h4>Adresa doručenia</h4>*/}
              <div>
                <span className="fw-semibold">{accountDetails.deliveryAddressLabel}</span>
                <span className="ms-2">{formatAddress(address)}</span>
              </div>
              <div>
                <button type="button" onClick={() => {
                  const shippingAddress = getValues('shippingAddress');
                  if (!shippingAddress) {
                    return;
                  }
                  shippingAddress.splice(index, 1);
                  setValue('shippingAddress', shippingAddress);
                }}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <button type="button" onClick={() => {
                  setAddressIndex(index);
                  openAddressModal();
                }}>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>
            </div>
          ))
        }

        <div className="adress">
          {/*<h4>Adresa doručenia</h4>*/}
          <div>
            <span className="fw-semibold">{accountDetails.newAddressDeliveryLabel}</span>
          </div>
          <button type="button" onClick={() => {
            setAddressIndex(getValues('shippingAddress')?.length ?? 0);
            openAddressModal();
          }}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        <button type="submit" className="btn --primary" disabled={loading}>
          {loading && <Spinner />}
          {!loading && accountDetails.saveAccountDetailsButtonLabel}
        </button>
      </form >
    </div >
  );
}

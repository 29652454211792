import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useStateContext } from '../../context/StateContext'

const Checkout = () => {

    const { totalQuantities } = useStateContext();

    if (totalQuantities === 0) {
        return <Navigate to={'/'} />
    }

    return (
        <Outlet/>
    )
}

export default Checkout
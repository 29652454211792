import React, { useState, useEffect } from "react";
import bottles from '../../assets/images/homepage-black.png';
// import vBottle from '../../assets/images/V_B.webp';
// import hBottle from '../../assets/images/H_B.webp';
// import fBottle from '../../assets/images/F_B.webp';
import icons from '../../assets/images/ikony.png';
import { Link, useLocation } from "react-router-dom";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import useSWR from "swr";
import axios from 'axios'
import ReactMarkdown from "react-markdown";
import { Homepage, FeaturedProduct } from "../../modals/modals";
import './Home.scss';
import { transformUrl as apiUrl } from "../../utils/fetch";
import { LoginModal } from "../../components/Modals/LoginModal";
import { ResetPasswordModal } from "../../components/Modals/ResetPasswordModal";
import Modal from "../../components/Modals/Modal";
import CashBackModal from "../../components/Modals/CashBackModal";
import { useStateContext } from "../../context/StateContext";



const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);

export const Home = () => {
  const { locale } = useStateContext();
  const url = `homepage?${locale ? `locale=${locale}&` : ''}`
    + 'populate[headerSection][populate]=*'
    + '&populate[heroSection][populate]=*'
    + '&populate[productsSection][populate][products][populate][product_category][populate][coverImage]=*'
    + '&populate[aboutProductSection][populate]=*'
    + '&populate[aspectSection][populate]=*'
    + '&populate[customerReviewsSection][populate][reviews][populate]=*'
    + '&populate[faqSection][populate]=*'
    + '&populate[footer][populate]=*'
    + '&populate[cashback][populate]=*';

  const { data } = useSWR<Homepage>(url, fetcher);
  const { host } = window.location;
  const [params, setParams] = useState('');
  const [resetPwdModal, setResetPwdModal] = useState(false);
  const location = useLocation()

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasClosedPopup, setHasClosedPopup] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setHasScrolled(true);
    if (!isPopupOpen && !hasClosedPopup && window.pageYOffset > 340) {
      setIsPopupOpen(true);
    }
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setHasClosedPopup(true);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeValue = queryParams.get('code');

    if (codeValue) {
      setParams(codeValue)
      setResetPwdModal(true);
    }
  });

  useEffect(() => {
    const header = document.getElementsByClassName('header').item(0);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const value = entry.target.getAttribute('data-anim');
          if (value === '--grey') {
            header?.classList.add('--grey');
          } else {
            entry.target.classList.add(value ?? '');
          }
        } else {
          const value = entry.target.getAttribute('data-anim');
          if (value === '--grey') {
            header?.classList.remove('--grey');
          } else {
            entry.target.classList.remove(value ?? '');
          }
        }
      })
    });

    const hidden = document.querySelectorAll('[data-anim]');
    hidden.forEach(e => observer.observe(e));

    return () => {
      hidden.forEach(e => observer.unobserve(e));
    }
  }, [data])

  return (
    <>
      {// (params && params.includes('code')) && <ResetPasswordModal />
        resetPwdModal && <ResetPasswordModal code={params} close={setResetPwdModal} />
      }

      <>
        <body>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-5TMJH46"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </body>
      </>


      <div className='section --welcome' data-anim="--grey">
        <div className="container-fluid">
          <div className='welcome row d-flex flex-column-reverse align-items-center justify-content-between flex-sm-row'>
            <div className='welcome-text col-12 col-sm-5' data-anim="fade-in">
              <h1 className='mb-4'>{data?.heroSection.heading}</h1>
              <Link to={data?.heroSection.toShopButton.url ?? '/#'} className='btn --primary'>{data?.heroSection.toShopButton.title}</Link>
            </div>
            <div className='welcome-img col-12 col-sm-7'>
              {/* TODO */}
             
              <img 
                src={apiUrl(data?.heroSection.image?.data.attributes.url ?? '/uploads/large_via_botanica_web_86a4597de4.png', host)}
                className='bottle-img' 
                alt='cbd-bottle' 
                // data-anim="scale-in" 
              />


              {/* <img src={bottles} className='bottle-img' alt='cbd-bottle' data-anim="scale-in" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className='top-products section'>
          <h2 className='mb-4 text-center'>{data?.productsSection.heading}</h2>


          {data?.cashback && hasScrolled && isPopupOpen && !hasClosedPopup && (
            <CashBackModal title={data.cashback.title || 'Cashback'} close={handleClosePopup}>
              <div className='row g-0 d-flex'>
                <span>{data.cashback.subtitle}</span>
                <span>{<br />}</span>
                {data.cashback.body && <div dangerouslySetInnerHTML={{ __html: data.cashback.body }}></div>}
                {/* <span>Pri nákupe do <strong>50€</strong> ti vrátime <strong>2€</strong>.</span>
                <span>Pri nákupe do <strong>100€</strong> ti vrátime <strong>5€</strong>.</span>
                <span>Pri nákupe nad <strong>100€</strong> ti vrátime <strong>10€</strong>.</span> */}
              </div>
            </CashBackModal>
          )}

          <div className="row d-flex justify-content-center gap-3 mb-3">
            {data?.productsSection.products.map((product: FeaturedProduct) => (
              product.product_category.data
                ? <CategoryCard
                  // key={product.id}
                  title={product.product_category.data.attributes.name.toLowerCase()}
                  to={'/produkty?category=' + product.product_category.data.attributes.name.toLowerCase()}
                  spec={product.title}
                  desc={product.subtitle ?? ''}
                  img={apiUrl(
                    product.product_category.data.attributes.coverImage?.data.attributes.url ?? '/uploads/V_B_small_671ae7b961.jpg',
                    host)}
                />
                : null
            ))}
            {/* <CategoryCard
              title="vitality"
              to="/produkty?category=vitality"
              spec="CBD + CBG"
              desc="full spectrum oleje"
              img={vBottle}
            />
            <CategoryCard
              title="focus"
              to="/produkty?category=focus"
              spec="CBG"
              desc="full spectrum oleje"
              img={fBottle}
            />
            <CategoryCard
              title="harmony"
              to="/produkty?category=harmony"
              spec="CBD"
              desc="full spectrum oleje"
              img={hBottle}
            />
            <CategoryCard
              title="vitality"
              to="/produkty?category=vitality"
              spec="CBD + CBG"
              desc="full spectrum oleje"
              img={vBottle}
            />
            <CategoryCard
              title="vitality"
              to="/produkty?category=vitality"
              spec="CBD + CBG"
              desc="full spectrum oleje"
              img={vBottle}
            /> */}
          </div>

          <div className="row justify-content-center align-items-center">
            <Link className="col-9 col-sm-5 col-md-4 col-lg-3 btn --tertiary" to={'/produkty'}>
              {data?.productsSection.toShopButton}
            </Link>
          </div>
        </div>
      </div>

      <div className='section--about' data-anim="fade-in">
        <div className="overlay">
          <div className="container-fluid">
            <div className='row d-flex flex-column-reverse flex-sm-row align-items-center'>
              <div className='col-12 col-sm-4 ml-auto mr-auto py-8'>
                <h2 className='mb-4'>{data?.aboutProductSection.title}</h2>
                <div className='aboutSection__text'>
                  <div dangerouslySetInnerHTML={{ __html: data?.aboutProductSection.text ?? '' }} />

                  {/* <ReactMarkdown>
                    {data?.aboutProductSection.text ?? ''}
                  </ReactMarkdown> */}
                </div>
                <img className="web-icons" src={icons} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className='section --aspect'>
          <h2 className='text-center mb-8'>{data?.aspectSection.heading}</h2>
          <div className='row d-flex flex-column align-items-center align-items-md-start justify-content-between flex-md-row'>
            <div className='col-12 col-md-4 d-flex flex-column' data-anim="fade-in-left">
              {data?.aspectSection.aspects.slice(0, 2).map(item => (
                <div key={item.id} className="mb-5">
                  <div className='d-flex align-items-center gap-1 mb-2'>
                    <h3 className="hero text-end flex-fill lh-1">{item.title}</h3>
                    <div className='check-badge'></div>
                  </div>
                  <div className='text-end hero-text fs-2'>
                    <div dangerouslySetInnerHTML={{ __html: item.text }} />

                    {/* <ReactMarkdown>
                      {item.text}
                    </ReactMarkdown> */}
                  </div>
                </div>
              ))}
            </div>
            <div className='col-12 col-md-4 drop-img' data-anim="fade-in"></div>
            <div className='col-12 col-md-4 d-flex flex-column' data-anim="fade-in-right">
              {data?.aspectSection.aspects.slice(2, 4).map(item => (
                <div key={item.id} className="mb-5">
                  <div className='d-flex align-items-center gap-1 mb-2'>
                    <div className='check-badge'></div>
                    <h3 className="hero text-start flex-fill">{item.title}</h3>
                  </div>
                  <div className='text-start hero-text fs-2'>
                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                    {/* <ReactMarkdown>
                      {item.text}
                    </ReactMarkdown> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='section'>
          <div className='row'>
            <h3 className='text-center mb-3'>{data?.customerReviewsSection.heading}</h3>
            <p className='text-center fs-2 mb-12'>
              {data?.customerReviewsSection.subheading}
            </p>
          </div>

          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-evenly gy-5' data-anim="fade-in-left">
            {
              data?.customerReviewsSection.reviews.map(
                (review) => {
                  return (
                    <div key={review.id} className='testimonial col'>
                      {/* <img src='https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80' */}
                      <img src={apiUrl(review.image?.data.attributes.url ?? '/uploads/blank_profile_picture_973460_480_84e42d5ac8.webp', host)}
                        alt='person'
                        className='testimonial-picture shadow'
                      />
                      <h5 className='fw-bold'>{review.title}</h5>
                      <h6 className='text-muted fw-bold'>{review.subtitle}</h6>
                      <blockquote>
                        “{review.text}”
                      </blockquote>
                    </div>
                  )
                }
              )
            }
          </div>
        </div>

        <div className='section'>
          <div className='row'>
            <h2 className='text-center mb-3'>{data?.faqSection.heading}</h2>
            <div className='col-12 d-flex flex-column gap-3'>
              {data?.faqSection.faqs.map(item => (
                <label key={item.id} className="question">
                  <input type="checkbox" />
                  <div className="question-title">{item.question}</div>
                  <div className="question-answer">
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                    {/* <ReactMarkdown>
                      {item.answer}
                    </ReactMarkdown> */}
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/*<footer className="footer">*/}
      {/*  <div className="container-fluid mr-auto ml-auto">*/}
      {/*    <div*/}
      {/*      className="row d-flex align-items-center justify-content-between">*/}
      {/*      <div className="col-12 col-md-4 footer__contact">*/}
      {/*        <span className="fw-bold">{data?.footer.contact.name}</span>*/}
      {/*        <span>{data?.footer.contact.addressLine1}</span>*/}
      {/*        <span>{data?.footer.contact.addressLine2}</span>*/}
      {/*        <span><a className="footer__nav-a"*/}
      {/*                 href="mailto:info@viabotanica.eu">{data?.footer.contact.email}</a></span>*/}
      {/*        <span><a className="footer__nav-a"*/}
      {/*                 href={`tel:${data?.footer.contact.phone}`}>{data?.footer.contact.phone}</a></span>*/}
      {/*        <span*/}
      {/*          className='mb-2'>{data?.footer.contact.vatInfo} {data?.footer.contact.companyId}</span>*/}
      {/*        <span className="fw-bold">Bankové spojenie</span>*/}
      {/*        <span>Slovenská sporiteľňa, a. s.</span>*/}
      {/*        <span>IBAN: SK44 0900 0000 0051 7793 8945</span>*/}
      {/*        <span>SWIFT: GIBASKBX</span>*/}
      {/*      </div>*/}

      {/*      <div className="col-12 col-md-4 footer__logo"></div>*/}

      {/*      <div*/}
      {/*        className="col-12 col-md-4 d-flex flex-column justify-content-between">*/}
      {/*        <ul className="footer__links mb-2">*/}
      {/*          {*/}
      {/*            data?.footer.navigation.map(link => {*/}
      {/*              return (*/}
      {/*                <li key={link.id} className="footer__nav-li">*/}
      {/*                  <a href={link.url} className="footer__nav-a">*/}
      {/*                    {link.title}*/}
      {/*                  </a>*/}
      {/*                </li>*/}
      {/*              )*/}
      {/*            })*/}
      {/*          }*/}
      {/*        </ul>*/}
      {/*        <div className='d-flex flex-column align-items-end'>*/}
      {/*          <span className="fw-bold text-white">Orgán dozoru</span>*/}
      {/*          <span className='text-white'>Inšpektorát SOI pre Bratislavský kraj</span>*/}
      {/*          <span className='text-white'>Bajkalská 21/A, P.O. Box 5, 820 07 Bratislava 27</span>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</footer>*/}
    </>
  );
}

import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import { IProduct, ProductPage } from "../../modals/modals";
import './ProductCard.scss';
import { transformUrl as apiUrl } from '../../utils/fetch';
import { Currency } from "../../utils/locale";

export interface ProductCardProps {
  product: IProduct,
  page: ProductPage,
  currency: Currency,
};

export const ProductCard: FC<ProductCardProps> = ({
  product,
  page,
  currency,
}: ProductCardProps) => {
  const { addToCart } = useStateContext();
  const { host } = window.location;

  function decapitalizeFirstLetter(s: string) {
    return s.charAt(0).toLowerCase() + s.slice(1);
  }

  if (!addToCart) {
    return <></>;
  }

  return (
    <div className='product col' style={{ "--data-iteration": "0" } as React.CSSProperties}>
      <div className="category-row">
        {
          product.productCategories.data.map((c, index) => {
            return <span key={index} className={`category --${decapitalizeFirstLetter(c.attributes.name)}`}>{c.attributes.name}</span>
          })
        }
      </div>
        {/*<span className={`product-sale --${decapitalizeFirstLetter(product.productCategories.data[0].attributes.name)}`}>*/}
        {/*    10%*/}
        {/*</span>*/}
      <Link to={`${product.id}`}>
        <img src={apiUrl(product.mainImage.data.attributes.url, host)} className='product-img' alt='product img' />
      </Link>
      <div className="product-text">
        <Link to={`${product.id}`} className='product-name'>{product.name}</Link>
        <div className="d-flex flex-column gap-1">
          <div className="w-100 mb-1">
            <span className='product-price'>
              <span className="product-price-currency">
                {currency.sign}
              </span>
              {product.price}
            </span>
          </div>
          {(product?.stockQuantity === 0) ? <span className='product-name mb-1 text-danger'>{page.emptyStock}</span>
            : ((product?.stockQuantity < 6) ? <span className='product-name mb-1 text-warning'>{page.lowStock}</span>
              : <span className='product-name mb-1 text-success'>{page.inStock}</span>)}
          <div className='product-footer'>
            <button
              type="button"
              disabled={product.stockQuantity === 0}
              className='icon-container'
              onClick={() => addToCart({ ...product, quantity: 0 }, 1, page.productErrors)}
            >
              {page.addToCartButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useLayoutEffect, useRef, useState } from 'react';
import './Banner.scss';

export interface BannerProps {
  buttonText: string;
  redirectUrl: string;
  srcset: {
    portrait?: {
      url: string;
      width: number;
    }[];
    landscape?: {
      url: string;
      width: number;
    }[];
  };
}

const dummySrcset = {
  portrait: [
    {
      url: 'port-600.webp',
      width: 600,
    },
    {
      url: 'port-1200.webp',
      width: 1200,
    },
    {
      url: 'port-2400.webp',
      width: 2400,
    },
  ],
  landscape: [
    {
      url: 'land-600.webp',
      width: 600,
    },
    {
      url: 'land-1200.webp',
      width: 1200,
    },
    {
      url: 'land-2400.webp',
      width: 2400,
    },
  ],
}

const Banner: React.FC<BannerProps> = ({
  srcset,
  redirectUrl,
  buttonText,
}: BannerProps) => {
  const [btnWidth, setBtnWidth] = useState(0);
  const [btnHeight, setBtnHeight] = useState(0);
  const [btnBottom, setBtnBottom] = useState(0);
  const [btnFontSize, setBtnFontSize] = useState(0);
  const imgRef = useRef<HTMLImageElement>(null);
  // const btnRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    window.location.href = redirectUrl;
  };
  const fmtSrcset: { portrait?: string, landscape?: string } = {};
  Object.keys(srcset).forEach(key => {
    const key_ = key as 'portrait' | 'landscape';
    fmtSrcset[key_] = srcset[key_]?.map(({ url, width }) => `${url} ${width}w`).join(', ');
  });
  const defaultSrc = srcset.landscape?.[0].url || srcset.portrait?.[0].url || '';

  const setBtnAttrs = () => {
    const img = imgRef.current;
    if (!img) {
      console.error("Image not found");
      return;
    }
      
    const h = (img.naturalHeight < img.height) ? img.naturalHeight : img.height;
    const diff = (img.naturalHeight < img.height) ? (img.height - img.naturalHeight) : 0;

    const newBtnHeight = h * 0.08;
    const newBtnWidth = h * 0.5 * 1.25;
    const newBtnBottom = h * 0.02 + diff / 2;
    const newFontSize = newBtnHeight * 0.5;

    setBtnHeight(newBtnHeight);
    setBtnWidth(newBtnWidth);
    setBtnBottom(newBtnBottom);
    setBtnFontSize(newFontSize);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", setBtnAttrs);

    // Clean up function
    return () => {
      window.removeEventListener("resize", setBtnAttrs);
    };
  }, []);

  return (
    <div className="banner__container">
      <div className="banner__image-container">
        <picture>
          <source srcSet={fmtSrcset.landscape} />
          <img
            ref={imgRef}
            className="banner__image"
            src={defaultSrc}
            alt="Clickable redirect"
            onLoad={setBtnAttrs}
          />
        </picture>
        <button
          // ref={btnRef}
          className="banner__button btn --primary"
          onClick={handleClick}
          style={{
            width: `${btnWidth}px`,
            height: `${btnHeight}px`,
            bottom: `${btnBottom}px`,
            fontSize: `${btnFontSize}px`,
          }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default Banner;

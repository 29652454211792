import axios from "axios";
import React, { createContext, FC, useContext, useEffect, useReducer } from "react";
import { UserAction } from "../enums/enums";
import { User } from "../modals/modals";

interface IUserContext {
  state: IState
}

interface IUserDispatchContext {
  dispatch: React.Dispatch<IAction>;
}

interface Props {
  children: React.ReactNode;
}

interface IState {
  user?: User;
  isAuthenticated: boolean;
}

export interface IAction {
  type: UserAction;
  user?: User;
}

const CurrentUserStateContext = createContext({} as IUserContext);
const CurrentUserDispatchContext = createContext({} as IUserDispatchContext);

const reducer = (_state: IState, action: IAction) => {
  switch (action.type) {
    case UserAction.Login:
      return { user: action.user, isAuthenticated: true };
    case UserAction.Logout:
      return { isAuthenticated: false };
    case UserAction.Updated:
      return { user: action.user, isAuthenticated: true };
    default:
      throw new Error(`unknown action ${action.type}`);
  }
};

export const CurrentUserProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { isAuthenticated: true });

  useEffect(() => {
    const getUser = async () => {
      await axios.get("/users/me?populate=*", {
        withCredentials: true
      }).then(d => {
        if (d.status === 200) {
          const user = d.data as User;
          dispatch({ type: UserAction.Login, user });
          return;
        } else {
          dispatch({ type: UserAction.Logout });
        }
      }).catch(e => dispatch({ type: UserAction.Logout }));
    }
    getUser();
  }, []);

  return (
    <CurrentUserDispatchContext.Provider value={{ dispatch }}>
      <CurrentUserStateContext.Provider value={{ state }}>
        {children}
      </CurrentUserStateContext.Provider>
    </CurrentUserDispatchContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserStateContext);
export const useDispatchCurrentUser = () => useContext(CurrentUserDispatchContext);

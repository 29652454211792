import React from 'react';
import './Modals.scss'
import {useStateContext} from "../../context/StateContext";
import { ProductPage } from '../../modals/modals';

export interface ConsentModalProps {
    page: ProductPage,
};

export const ConsentModal = ({
    page,
}: ConsentModalProps) => {
    const { setConsentModal } = useStateContext();
    const texts = page.consentModal;
    return (
        <div className="modal">
            <div className="modal-overlay --consent"></div>
            <div className="modal-content --consent">
                <h4 className='text-center mb-2 fs-1'>{texts.title.toUpperCase()}</h4>
                <p className='text-center mb-2'>
                    {texts.body}
                </p>
                <div className='row gap-1 justify-content-center align-items-center px-2 px-sm-0'>
                    <button type='button' value='cancel' className='btn --primary col-sm-5' onClick={() => {
                        localStorage.setItem('consent', 'false')
                        setConsentModal(false);
                    }}>
                        {texts.confirmButton}
                    </button>
                    <a href='/' className='btn --tertiary col-sm-5'>
                        {texts.declineButton}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ConsentModal;

import React from 'react';
import Paperwork from "./Paperwork";

const Gdpr = () => {
    return (
        <Paperwork url='gdpr'/>
    );
};

export default Gdpr;

import { IRegisterInputs } from "../components/Modals/RegisterModal";
import { IAction } from "../context/UserContext";
import { UserAction } from "../enums/enums";
import { User } from "../modals/modals";
import React, { useState } from "react";
import toast from "react-hot-toast";
import axios, { AxiosResponse } from "axios";


export const registerUser = async (data: IRegisterInputs, success: (response: AxiosResponse) => void, failed: (error?: Error) => void) => {
    try {
        const response = await axios.post('/auth/local/register', data)

        if (response.status === 200) {
            success(response);
        } else {
            failed();
        }
    } catch (e) {
        if (e instanceof Error) {
            failed(e); // works, `e` narrowed to Error
        } else {
            failed();
        }
    }
}

import React from "react";
import './OrderHistory.scss';
import axios from "axios";
import useSWR from "swr";
import { Homepage, Order, OrderHistoryLabels, ProfilePage } from "../../../modals/modals";
import { useOutletContext } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";


const mapStatusToLabel = (status: string, labels: OrderHistoryLabels): string => {
    const statusMapping: { [key: string]: keyof OrderHistoryLabels } = {
        CREATED: 'createdLabel',
        FAILED: 'failedLabel',
        CANCELLED: 'cancelledLabel',
        AWAITING_PAYMENT: 'awaitingPaymentLabel',
        READY_TO_SHIP: 'readyToShipLabel',
        SENT: 'sentLabel',
        RETURNED: 'returnedLabel',
        DELIVERED: 'deliveredLabel',
        PENDING: 'pendingLabel',
        WAITING_FOR_CONFIRMATION: 'waitingForConfirmationLabel',
        COMPLETED: 'completedLabel',
        CANCELED: 'canceledLabel',
        IN_DELIVERY: 'inDeliveryLabel'
    };

    const labelKey = statusMapping[status] || 'failedLabel';
    return labels[labelKey];
};

const fetcher = (url: string) => axios.get(url).then(res => res.data.data);

const OrderHistory = () => {
    const { host } = window.location;
    const {data: {orderHistory}} = useOutletContext<{data: ProfilePage}>();

    const { data } = useSWR<Order[]>('orders/me', fetcher);

    if (!orderHistory) {
        return <Loader />
    }

    console.log(mapStatusToLabel('SENT', orderHistory))

    return (
        <>
            {
                (!data || data?.length === 0) && <span>{orderHistory.noOrdersLabel}</span>
            }
            <div className='orders'>
                {data?.map((order, index) => {
                    return (
                        <div className='order'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <h3>{new Date(order.createdAt).toLocaleString('sk')}.{orderHistory.orderLabel}</h3>
                                <span className={`order__status --${order.status.toLowerCase()}`}>{mapStatusToLabel(order.status, orderHistory)}</span>
                            </div>
                            <span>{orderHistory.orderItemsLabel}</span>
                            <div className='order__items'>
                                {
                                    order.cart.map(i => {
                                        return (
                                            <div className='order__item'>
                                                <img className='item__img' src={'https://api.' + host + i.productWithoutId.mainImage.url} />
                                                <span className='item__name'>{i.productWithoutId.name}</span>
                                                <span>{i.quantity}x{i.productWithoutId.price} &euro;</span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                }
                )}
            </div>
        </>
    );
}

export default OrderHistory
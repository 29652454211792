import { faShoppingCart, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useSWR from "swr";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import { useCurrentUser } from "../../context/UserContext";
import { PageHeader, NavItem } from "../../modals/modals";
import LocaleSelector from "../LocaleSelector/LocaleSelector";

const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);

export const Header = () => {
  const {
    setShowCart,
    menuVisibility,
    totalQuantities,
    setMenuVisibility,
    setLoginModal,
    locale
  } = useStateContext();
  const url = 'header?populate=*' + (locale ? `&locale=${locale}` : '');

  const { data } = useSWR<PageHeader>(url, fetcher);
  const user = useCurrentUser();

  if (!setShowCart) {
    return <div></div>
  }

  return (
    <>
      <head>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5TMJH46');`
          }}
        />

      </head>
      <header className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-7 col-sm-9 col-md-2 d-flex align-items-center">
              <div className="header__logo">
                <NavLink
                  to={'/'}
                  className="header__logo-a"
                  style={{ backgroundImage: `url(${data?.logo.data.attributes.url}) no-repeat center` }}
                ></NavLink>
              </div>
            </div>

            <div className="col-5 col-sm-3 col-md-8 d-flex align-items-center justify-content-end">
              <ul className="header__nav">
                {data?.navigation.map((item: NavItem) => (
                  <li className="header__nav-li" >
                    <NavLink
                      key={item.id}
                      to={item.url}
                      className="header__nav-a scroll-to-anchor">
                      {item.title}
                    </NavLink>
                  </li>
                ))}
                {/*<li className="header__nav-li">*/}
                {/*  <NavLink to={'/'} className="header__nav-a scroll-to-anchor">*/}
                {/*    Domov*/}
                {/*  </NavLink>*/}
                {/*</li>*/}

                {/*<li className="header__nav-li">*/}
                {/*  <NavLink to={'/produkty'} className="header__nav-a scroll-to-anchor">*/}
                {/*    Shop*/}
                {/*  </NavLink>*/}
                {/*</li>*/}

                {/*<li className="header__nav-li">*/}
                {/*  <NavLink to={'/blogs'} className="header__nav-a scroll-to-anchor">*/}
                {/*    Blog*/}
                {/*  </NavLink>*/}
                {/*</li>*/}

                {/*<li className="header__nav-li">*/}
                {/*  <NavLink to={'/o-nas'} className="header__nav-a scroll-to-anchor">*/}
                {/*    O nás*/}
                {/*  </NavLink>*/}
                {/*</li>*/}

                {/*<li className="header__nav-li">*/}
                {/*    <NavLink to={'/kontakt'} className="header__nav-a scroll-to-anchor">*/}
                {/*        Kontakt*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {!user.state.isAuthenticated && <li className="header__nav-li">
                  <button
                    className="btn --secondary mx-auto"
                    type="button"
                    onClick={() => setLoginModal(true)}
                  >{data?.signInButton}</button>
                </li>}

                {user.state.isAuthenticated && <li className="header__nav-li">
                  <NavLink to={'/moj-ucet'} className="header__nav-a scroll-to-anchor">
                    <FontAwesomeIcon icon={faUserCircle} />
                  </NavLink>
                </li>}

                <li className="header__nav-li--contact fs-2 d-md-none">
                  <p className="fw-bold">{data?.contactInfo.text}</p>
                  <p>{data?.contactInfo.phoneNumber}</p>
                  <p>{data?.contactInfo.hotlineTime}</p>
                </li>

              </ul>

              <div className="position-relative me-1 me-md-0">
                <LocaleSelector />
              </div>

              <span
                className="header__nav-a scroll-to-anchor cart"
                onClick={() => setShowCart(true)}
              >
                <FontAwesomeIcon icon={faShoppingCart} />
                <span className="cart-bubble">{totalQuantities}</span>
              </span>

              <div className="header__hamburger" onClick={() => setMenuVisibility(!menuVisibility)}>
                <div className="header__hamburger-icon">
                  <span className="header__hamburger-line"></span>
                  <span className="header__hamburger-line"></span>
                  <span className="header__hamburger-line"></span>
                  <span className="header__hamburger-line"></span>
                </div>
              </div>
            </div>
            <div className="col-md-2 text-end my-auto d-none d-md-block">
              <p className="fw-bold">{data?.contactInfo.text}</p>
              <p>{data?.contactInfo.phoneNumber}</p>
              <p>{data?.contactInfo.hotlineTime}</p>
            </div>
          </div>
        </div>
      </header >
    </>
  );
}

import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";
import Spinner from "../../../components/Spinner/Spinner";
import { useDispatchCurrentUser } from "../../../context/UserContext";
import { UserAction } from "../../../enums/enums";
import { ProfilePage, User } from "../../../modals/modals";
import Loader from "../../../components/Loader/Loader";

interface IInputs {
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
}

const registerSchema = yup.object({
    currentPassword: yup
        .string()
        .required(),
        // .min(8)
        // .required('Staré heslo je povinné'),
    password: yup
        .string()
        .min(8, 'Minimálna dĺžka hesla je 8 znakov')
        .required(),

        // .required('Nové heslo je povinné'),
    passwordConfirmation: yup
        .string()
        // .oneOf([yup.ref("password")], "Heslá sa nezhodujú")
        .oneOf([yup.ref("password")])
        .required(),

        // .oneOf([yup.ref("password")], "Heslá sa nezhodujú")


        // .required('Nové heslo je povinné'),
}).required();


export const AccountDisable = () => {
    const {data: {changePassword}} = useOutletContext<{data: ProfilePage}>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { dispatch } = useDispatchCurrentUser();
    const { register, handleSubmit, formState: { errors } } = useForm<IInputs>({
        resolver: yupResolver(registerSchema)
    });

    const onSubmit = async (data: IInputs) => {
        setLoading(true);
        try {
            const response = await axios.post('/auth/change-password', data);

            if (response.status !== 200) {
                setLoading(false);
                toast.error(changePassword.toastError1Label)
                return;
            }

            // if (response.message === 'Your new password must be different than your current password') {
            //     setLoading(false);
            //     toast.error('Nové heslo sa nemôže zhodovať so starým');
            //     return;
            // }

            dispatch({ type: UserAction.Updated, user: response.data.user as User })
            setLoading(false);
            // navigate('/moj-ucet');
            toast.success(changePassword.toastSuccessLabel)
        } catch (e) {
            const error = e as AxiosError;
            setLoading(false);
            toast.error(changePassword.toastError1Label)
            if (error.response?.status === 500) {
                toast.error(changePassword.toastError2Label)
            }
        }
    }

    if (!changePassword) {
        return <Loader />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* <p className='text-danger'>{errors.currentPassword?.message}</p> */}
            {errors.currentPassword && (
                <p className='text-danger'>
                    {changePassword.oldPasswordSchemaLabel}
                </p>
            )}
            <div className='mb-1'>
                <label>{changePassword.oldPasswordLabel}</label>
                <input type='password' {...register('currentPassword')} />
            </div>


            {/* <p className='text-danger'>{errors.password?.message}</p> */}
            {errors.password && (
            <p className='text-danger'>
                {errors.password.type === 'min' 
                    ? changePassword.newPasswordSchema1Label
                    : errors.password.type === 'required' 
                    ? changePassword.newPasswordSchema2Label
                    : errors.password.message}
            </p>
        )}
            <div className='mb-1'>
                <label>{changePassword.newPasswordLabel}</label>
                <input type='password'  {...register('password')} />
            </div>


            {/* <p className='text-danger'>{errors.passwordConfirmation?.message}</p> */}
            {errors.passwordConfirmation && (
            <p className='text-danger'>
                {errors.passwordConfirmation.type === 'min' 
                    ? changePassword.repeatNewPasswordSchema1Label
                    : errors.passwordConfirmation.type === 'required' 
                    ? changePassword.repeatNewPasswordSchema2Label
                    : errors.passwordConfirmation.message}
            </p>
        )}
            <div className='mb-1'>
                <label>{changePassword.repeatNewPasswordLabel}</label>
                <input type='password' {...register('passwordConfirmation')} />
            </div>

            <button type='submit' className='btn --primary'>
                {!loading && changePassword.changePasswordButtonLabel}
                {loading && <Spinner />}
            </button>
        </form>
    );
}

import axios, { AxiosRequestHeaders } from "axios";
import React, { useState } from "react";
import useSWRInfinite from 'swr/infinite';
import { BlogItem } from "../../components/BlogItem/BlogItem";
import Loader from "../../components/Loader/Loader";
import { Blog } from "../../modals/modals";
import { useStateContext } from "../../context/StateContext";
import { localizedUrl } from "../../utils/locale";

export const BlogList = () => {
  const { locale } = useStateContext();
  const fetcher = (url: string, header: AxiosRequestHeaders) => {
    return axios.get(url, {
      headers: header,
    }).then((response) => {
      if (response.data.meta.pageCount) {
        setPageCount(response.data.meta.pageCount);
        setCurrentPage(response.data.meta.page);
      }
      return response.data.data;
    });
  }

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const { data, size, setSize } = useSWRInfinite(
    (index) => localizedUrl('blog-posts', {
      'pagination[page]': currentPage.toString(),
      populate: '*'
    }, locale),
    fetcher
  );

  let blogs = data ? [].concat(...data) : [];
  const formattedBlogs = blogs.map(
    (x: { id: number, attributes: Blog }, index) => ({
      ...x.attributes, id: x.id, index: index
    }));


  return (
    <>
      {
        !data && <Loader />
      }

      {
        data?.length === 0 && <p>Nenašli sa žiadne blogy.</p>
      }

      {/* <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gy-3 pb-5"> */}
      {/* <div className="BlogList"> */}
      {
        formattedBlogs.map(blog => {

          return <BlogItem
            // key={blog.id}
            {...blog}

          />
        })
      }
      {/* </div> */}
    </>
  );
}

export type Country =
  | 'at'
  | 'be'
  | 'bg'
  | 'cy'
  | 'cz'
  | 'de'
  | 'dk'
  | 'ee'
  | 'es'
  | 'fi'
  | 'fr'
  | 'gb'
  | 'gr'
  | 'hu'
  | 'hr'
  | 'ie'
  | 'it'
  | 'lt'
  | 'lu'
  | 'lv'
  | 'mt'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'se'
  | 'si'
  | 'sk';

export type Language = 'cs' | 'sk' | 'da';

export interface Locale {
  countryCode: Country;
  countryName: string;
  languageCode: Language;
  languageName: string;
  currency: string;
  currencyCode: string;
  flagSrc: string;
}

export const locales: Record<string, Locale> = {
  // 'cs-cz': {
  //   countryCode: 'cz',
  //   countryName: 'Česká republika',
  //   languageCode: 'cs',
  //   languageName: 'Čeština',
  //   currency: 'Kč',
  //   currencyCode: 'CZK',
  // },
  'sk': {
    countryCode: 'sk',
    countryName: 'Slovensko',
    languageCode: 'sk',
    languageName: 'Slovenčina',
    currency: '€',
    currencyCode: 'EUR',
    flagSrc: 'https://icons.iconarchive.com/icons/wikipedia/flags/64/SK-Slovakia-Flag-icon.png',
  },
  'da': {
    countryCode: 'dk',
    countryName: 'Danmark',
    languageCode: 'da',
    languageName: 'Dansk',
    currency: 'kr',
    currencyCode: 'DKK',
    flagSrc: 'https://icons.iconarchive.com/icons/wikipedia/flags/64/DK-Denmark-Flag-icon.png',
  },
};

export const DEFAULT_LOCALE = 'sk';

export const countries: Record<Country, string> = {
  at: 'Austria',
  be: 'Belgium',
  bg: 'Bulgaria',
  cy: 'Cyprus',
  cz: 'Česká republika',
  de: 'Germany',
  dk: 'Denmark',
  ee: 'Estonia',
  es: 'Spain',
  fi: 'Finland',
  fr: 'France',
  gb: 'United Kingdom',
  gr: 'Greece',
  hu: 'Hungary',
  hr: 'Croatia',
  ie: 'Ireland',
  it: 'Italy',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  lv: 'Latvia',
  mt: 'Malta',
  nl: 'Netherlands',
  pl: 'Poland',
  pt: 'Portugal',
  ro: 'Romania',
  se: 'Sweden',
  si: 'Slovenia',
  sk: 'Slovensko',
};

export interface DeliveryOption {
  name: string;
  price: number;
  carrier: string;
  __component: string;
  fieldName: string;
}

export const deliveryMethodKeys = [
  'packetaPickupPoint',
  'packetaAddressDelivery',
  'glsAddressDelivery',
] as const;
export type DeliveryMethodKey = typeof deliveryMethodKeys[number];

export const carriers = [
  'packeta',
  'gls',
] as const;
export type Carrier = typeof carriers[number];

export interface DeliveryMethod {
  id: number;
  name: string;
  enabled: boolean;
  fee: number;
  component: string;
  carrier: Carrier;
  localeName?: string;
}

export type DeliveryMethods = Record<DeliveryMethodKey, DeliveryMethod>;

export interface PaymentMethodOld {
  name: string;
  price: number;
  isCod: boolean;
}

export const paymentMethodTypes = [
  'cashOnDelivery',
  'payBeforeCreate',
  'payAfterCreate',
] as const;
export type PaymentMethodType = typeof paymentMethodTypes[number];

export const paymentMethodKeys = [
  'cashOnDelivery',
  'paymentCard',
  'wireTransfer',
] as const;
export type PaymentMethodKey = typeof paymentMethodKeys[number];

export interface PaymentMethod {
  enabled: boolean;
  name: string;
  fee: number;
  type: PaymentMethodType;
  backendKey: string;
}
export type PaymentMethods = Record<PaymentMethodKey, PaymentMethod>;

import React from 'react'
import './Loader.scss';

const Loader = () => {
    return (
        <div className='dots'>
            <div className='dot --special'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
        </div>
    )
}

export default Loader
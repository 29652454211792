import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { AxiosRequestHeaders } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useOutletContext } from "react-router-dom";
import useSWRInfinite from 'swr/infinite';
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { useStateContext } from "../../context/StateContext";
import { Category, IProduct, ProductPage, SortOption } from "../../modals/modals";
import './Shop.scss';
import { Currency, getCurrencyFromLocale, localizedUrl } from "../../utils/locale";
import _ from 'lodash';
import { DEFAULT_LOCALE } from "../../assets/constants";
// import ConsentModal from "../../components/Modals/ConsentModal";

// const sortOptions = {
//   'Od najlacnejších': 'asc',
//   'Od najdrahších': 'desc',
//   'Zoradiť podľa': ''
// }

const categsFetcher = (url: string) => axios.get(url).then(res => res.data.data);

export const Shop = () => {
  const { selectedCategory, setSelectedCategory, locale } = useStateContext();
  const { page } = useOutletContext<{ page: ProductPage }>();
  const { data: categories } = useSWR<Category[]>(
    localizedUrl('product-categories', {}, locale),
    categsFetcher
  );
  const [currency, setCurrency] = useState<Currency>(getCurrencyFromLocale(DEFAULT_LOCALE));
  useEffect(() => {
    if (locale) {
      setCurrency(getCurrencyFromLocale(locale));
    }
  }, [locale]);

  const sortOptions = _.omit(page?.sortOptions, ['id']);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setSelectedCategory(searchParams.get("category") ?? 'all');
  }, [searchParams, setSelectedCategory])

  function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  const prodFetcher = (url: string, header: AxiosRequestHeaders) => {
    return axios.get(url, {
      headers: header,
    }).then((response) => {
      if (response.data.meta.pageCount) {
        setPageCount(response.data.meta.pageCount);
        setCurrentPage(response.data.meta.page);
      }
      return response.data.data;
    });
  }

  const [sortOption, setSortOption] = useState<SortOption>('none');
  // const [sortOption, setSortOption] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showSortOptions, setShowSortOptions] = useState(false);

  const getSearchParams = (sortOpt: SortOption, selectedCat: string) => {
    const sp: Record<string, string> = {
      'pagination[page]': currentPage.toString(),
      'populate': '*',
    };
    if (sortOpt !== 'none') {
      sp['sort'] = 'price:' + sortOpt;
    }
    if (selectedCat !== 'all') {
      sp['filters[productCategories][name][$eq]'] = selectedCat;
    }

    return sp;
  }

  // function f(index: number): string {
  //   return localizedUrl(
  //     'products',
  //     getSearchParams(sortOption, selectedCategory),
  //     locale
  //   );
  // }
  const { data: productsData, size, setSize } = useSWRInfinite(
    index => localizedUrl(
      'products',
      getSearchParams(sortOption, selectedCategory),
      locale
    ),
    prodFetcher
  );

  let products = productsData ? [].concat(...productsData) : [];
  const formattedProducts = products.map((x: { id: number, attributes: IProduct }) => ({
    ...x.attributes,
    id: x.id
  }))

  // const { consentModal } = useStateContext();
  if (!page) {
    return <Loader />
  }

  return (
    <div>
      <div className={`banner --${selectedCategory.toLowerCase()} mb-3`}>
        <div className="row">
          <div className="container-fluid">
            <h3 className="mb-3">{page.title}</h3>
            {/*{categories?.find(*/}
            {/*  (x: Category) => x.attributes.name === selectedCategory)?.attributes.ageProtected*/}
            {/* {formattedProducts.some((x: IProduct) => x.productCategories.data.some((y: Category) => y.attributes.ageProtected))
              && consentModal
              && <ConsentModal />} */}
            <div className="shop-nav">
              <Link to={'?category=all'}>
                {capitalizeFirstLetter(page.all)}
              </Link>
              {categories?.map((x: Category) => (
                <Link to={`?category=${x.attributes.name}`} key={x.id}>
                  {capitalizeFirstLetter(x.attributes.name)}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-center mb-5">
          <div
            className="col row justify-content-between align-items-center shop-header">
            <span className="col-auto fw-bold">
              {selectedCategory === 'all'
                ? capitalizeFirstLetter(page.all)
                : categories?.find(
                  (x: Category) => x.attributes.name === selectedCategory)
                  ?.attributes?.effects?.toUpperCase() || ''}
            </span>

            <div className="col-auto select-wrapper">
              <div className="select" onClick={() => {
                setShowSortOptions(prev => !prev)
              }}>
                {/* <span>{sortOption === 'none' ? 'Zoradiť podľa' : sortOption}</span> */}
                {sortOptions && <span>{sortOptions[sortOption]}</span>}
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              {sortOptions && showSortOptions && <div className="select-dropdown">
                {Object
                  .entries(sortOptions)
                  .filter(([k,]) => k !== 'none')  // CHECK
                  .map(([k, v], i) => (
                    <div key={i} onClick={() => {
                      setSortOption(k as SortOption);
                      setShowSortOptions(false);
                    }}>
                      {v}
                    </div>
                  ))
                }
                {/*<span onClick={() => {*/}
                {/*  setSortOption('Od najlecnejších')*/}
                {/*  setShowSortOptions(false)*/}
                {/*}}>Od najlecnejších</span>*/}
                {/*<span onClick={() => {*/}
                {/*  setSortOption('Od najdrahších')*/}
                {/*  setShowSortOptions(false)*/}
                {/*}}>Od najdrahších</span>*/}
              </div>}
            </div>
          </div>
        </div>

        <div className='row justify-content-center'>
          {
            !productsData && <Loader />
          }
          {/* justify-content-lg-between */}
          <div className="col-12 row align-items-center row-cols-3 gap-3 justify-content-evenly
                      justify-content-lg-start pb-3">
            {
              formattedProducts.map((product: IProduct) => {
                return <ProductCard key={product.id} product={product} page={page} currency={currency} />
              })
            }

            {
              productsData && formattedProducts.length === 0 &&
              <p>{page.noProductsFound}</p>
            }
          </div>

          {currentPage < pageCount &&
            <button type="button" className="btn --tertiary ms-auto me-auto"
              onClick={() => {
                setCurrentPage(prev => prev + 1);
              }}>
              {page.nextPageButton}
            </button>}
        </div>
      </div>
    </div>
  );
}

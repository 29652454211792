import React from 'react';
import './Spinner.scss'

const Spinner = () => {
    return (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center gap-1">
            <div className="dot1 rounded-circle">
            </div>
            <div className="dot2 rounded-circle">
            </div>
            <div className="dot3 rounded-circle">
            </div>
        </div>
    );
};

export default Spinner;

import React from 'react';
import Paperwork from "./Paperwork";

const TermsAndConditions = () => {
    return (
        <Paperwork url='terms-and-conditions'/>
    );
};

export default TermsAndConditions;

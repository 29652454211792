import React from 'react';
import axios from 'axios';
import useSWR from 'swr';
import {NavItem, PageFooter} from "../../modals/modals";
import { useStateContext } from '../../context/StateContext';
import { localizedUrl } from '../../utils/locale';


const fetcher = (url: string) => axios.get(url).then(res => res.data.data.attributes);

export const Footer = () => {
  const { locale } = useStateContext();
  const url = localizedUrl('footer', { populate: '*' }, locale);
  const {data} = useSWR<PageFooter>(url, fetcher);

  return (
    <footer className="footer">
      <div className="container-fluid mr-auto ml-auto">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-12 col-md-4 footer__contact">
            <span className="fw-bold">{data?.contact.name}</span>
            <span>{data?.contact.addressLine1}</span>
            <span>{data?.contact.addressLine2}</span>
            <span>
              <a
                className="footer__nav-a"
                href={"mailto:" + data?.contact.email ?? "info@viabotanica.eu"}
              >
                {data?.contact.email}
              </a>
            </span>
            <span>
              <a
                className="footer__nav-a"
                href={`tel:${data?.contact.phone}`}
              >
                {data?.contact.phone}
              </a>
            </span>
            <span
              className='mb-2'>{data?.contact.vatInfo}{', '}{data?.contact.companyId}
            </span>
            <span className="fw-bold">{data?.banking.title}</span>
            <span>{data?.banking.bankName}</span>
            <span>{data?.banking.line1}</span>
            <span>{data?.banking.line2}</span>
          </div>

          <div className="col-12 col-md-4 footer__logo"></div>

          <div
            className="col-12 col-md-4 d-flex flex-column justify-content-between">
            <ul className="footer__links mb-2">
              {
                data?.navigation.map((item: NavItem) => {
                  return (
                    <li key={item.id} className="footer__nav-li">
                      <a href={item.url} className="footer__nav-a">
                        {item.title}
                      </a>
                    </li>
                  )
                })
              }
            </ul>
            <div className='d-flex flex-column align-items-end'>
              <span className="fw-bold text-white">
                {data?.supervisor.title}
              </span>
              <span className='text-white'>{data?.supervisor.line1}</span>
              <span className='text-white'>{data?.supervisor.line2}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
